import { takeLatest, select, call } from "redux-saga/effects";
import { getBranchAvgPrepTimeAction } from "../../constants/actions";
import { handleOptimism } from "./handleOptimism";
import {
  getBranchAvgPrepTimeSuccess,
  getBranchAvgPrepTimeFailure,
  getBranchAvgPrepTime
} from "../actions/branchAvgPrepTimeActions";
import { selectToken } from "../../../redux-store/selectors";
import { branchAveragePrepTimeApi } from "../../axios/branchAveragePrepTime";
import { union } from "ts-action";

const actionType = union({ getBranchAvgPrepTime });

function* getBranchAvgPrepTimeSaga(action: typeof actionType) {
  const token = yield select(selectToken);
  const res = yield call(branchAveragePrepTimeApi.get, token, "action.payload");
  return res.data;
}

export function* watchGetBranchAvgPrepTime() {
  yield takeLatest(
    getBranchAvgPrepTimeAction.requested,
    handleOptimism(getBranchAvgPrepTimeSaga, {
      success: getBranchAvgPrepTimeSuccess,
      failure: getBranchAvgPrepTimeFailure
    })
  );
}
