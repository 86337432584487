import { reducer } from "ts-action";
// import {
// getBranchAvgPrepTimeSuccess,
// changeBranchAvgPrepTime
// } from "../actions/branchAvgPrepTimeActions";
import { IBranchAvgPrepTime } from "../../axios/branchAveragePrepTime";
import { withLoadingReducer, ILoadingState } from "./withLoadingState";
import { getBranchAvgPrepTimeAction } from "../../constants/actions";
import { optimistic, OptimisticState } from "redux-optimistic-ui";
import { Reducer } from "redux";
// import { receiveBranchAveragePrepTimeChange } from "../actions/socket-actions";

export const branchAvgPrepTimeReducer = optimistic(
  withLoadingReducer(
    reducer(
      [
        // on(
        //   {
        //     getBranchAvgPrepTimeSuccess,
        //     changeBranchAvgPrepTime,
        //     receiveBranchAveragePrepTimeChange
        //   },
        //   (s, action) => action.payload
        // )
      ],
      {}
    ),
    getBranchAvgPrepTimeAction
  )
) as Reducer<
  OptimisticState<{ [branchId: string]: IBranchAvgPrepTime } & ILoadingState>
>;
