import axios from "./axios";
import * as Requests from "./requests";

export const assistanceRequest = (token: string) => {
  return axios.post(
    Requests.assistanceRequest,
    {},
    {
      headers: {
        token
      }
    }
  );
};
