export default {
  orderSettings: "Order Settings",
  branchListing: "Branch listing",
  placeholderForOrderSettings: "Search for Branch name…",
  avgOrderTime: "Average order time",
  orderingNotAvailable: "Ordering Not Available",
  orderingAvailable: "Ordering Available",
  pickup: "Pickup",
  delivery: "Delivery",
  edit: "Edit “{{name}}” ordering settings",
  orderAvailability: "Order availability",
  avgTime: "Average time",
  deliveryTime: "Delivery time",
  preparationTime: "Preparation time",
  minutes: "Minutes",
  to: "To",
  from: "From",
  save: "Save",
  time: "Time",
  inactive: "Inactive",
  open: "Open",
  closed: "Closed",
  disconnected: "Disconnected"
};
