import React from "react";
import * as styles from "./styles.module.sass";
import { uDIC } from "../../../components/lib/useDefaultAndInsertedClassName";

interface IProps {
  label?: string;
  iconPath: string;
  className?: string;
  IconClass?: string;
  onClick?: () => void;
}
export const GenericButton: React.FC<React.ButtonHTMLAttributes<
  HTMLButtonElement
> &
  IProps> = props => {
  const { iconPath, IconClass, ...rest } = props;
  const icon = <img className={uDIC(styles.icon, IconClass)} src={iconPath} />;

  return (
    <button
      type="button"
      {...(rest as any)}
      className={uDIC(styles.genericButtonDefaults, props.className)}
    >
      {props.label}
      {icon}
    </button>
  );
};
