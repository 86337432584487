import * as Strings from "../strings/header";
export default {
  [Strings.availableTitle]: "Ordering on",
  [Strings.unAvailableTitle]: "Ordering off",
  [Strings.avgTime]: "Average prep time",
  [Strings.contactSupport]: "Request support",
  [Strings.label]: "Now on:",
  [Strings.from]: "from",
  [Strings.to]: "to",
  [Strings.minute]: "minute",
  [`${Strings.minute}_plural`]: "{{count}} minutes",
  [Strings.save]: "Save changes",
  [Strings.pointingSystem]: "Loyalty manager",
  [Strings.requestsSystem]: "Orders manager",
  [Strings.current]: "(Current)",
  [Strings.transactions]: "Transactions",
  [Strings.contactSupportTitle]:
    "Please confirm the help request and the support team contact you immediately",
  [Strings.min]: "min",
  [Strings.time]: 'Time',
  [Strings.pickupAverage]: 'Pickup Time',
  [Strings.deliveryAverage]: 'Delivery Time'

};
