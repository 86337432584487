export const noResultsFound = "Couldn't find any items";
export const itemAvailable = "Item available";
export const itemIsSoldOut = "Item is sold out today";
export const itemIsSoldOutDescription =
  "This item will be available on the next day automatically";
export const itemIsRunOutIndefinitely = "Item run out indefinitely";
export const itemIsRunOutIndefinitelyDescription =
  "This item will be available when you change its status manually";
export const hideItemFromTheBranch = "Hide item from the branch";
export const hideItemFromTheBranchDescription =
  "Customers can't view and order the item from this branch";
export const itemSettings = "Item settings";
export const saveChanges = "Save changes";
export const menu = "Menu";
export const selectTheItemState = "Select the item state";
export const selectTheCategoryState = "Select the category state";
export const selectTheModifierGroupState = "Select the modifier group state";
export const selectTheModifierItemState = "Select the modifier item state";
export const cancelSimilarChanges = "Cancel";
export const confirmSimilarChanges = "Change similar modifiers";
export const changeSimilarTitle = "Change similar modifiers items";
export const changeSimilarSubTitle =
  "{{similarCount}} modifiers items are similar to this item, Click on 'Change similar modifiers' to apply changes to  all or 'Cancel' to save only one item";
export const categoryIsSoldOut = "Category is sold out today";
export const categoryIsSoldOutDescription =
  "This category will be available on the next day automatically";
export const categoryIsRunOutIndefinitely = "Category is run out indefinitely";
export const categoryIsRunOutIndefinitelyDescription =
  "This category will be available when you change its status manually";
export const modifierGroupIsSoldOut = "Modifier Group is sold out today";
export const modifierGroupIsSoldOutDescription =
  "This modifier group will be available on the next day automatically";
export const modifierGroupIsRunOutIndefinitely =
  "Modifier group is run out indefinitely";
export const modifierGroupIsRunOutIndefinitelyDescription =
  "This modifier group will be available when you change its status manually";
export const modifierItemIsSoldOut = "Modifier Item is sold out today";
export const modifierItemIsSoldOutDescription =
  "This modifier item will be available on the next day automatically";
export const modifierItemIsRunOutIndefinitely =
  "Modifier Item is run out indefinitely";
export const modifierItemIsRunOutIndefinitelyDescription =
  "This modifier item will be available when you change its status manually";
