import { put, delay, select, takeLatest } from "redux-saga/effects";
import { revokeOrderAction } from "../../constants/actions";
import { IRootReducerState } from "../../../redux-store/rootReducer";
import { clearPrevState, revokeOrderFailure } from "../actions/socket-actions";
import i18n from "../../../i18n/i18n";
import * as Strings from "../../../CallCenter-App/i18n/strings/orderingBoard";
import { notify } from "react-notify-toast";
function* revokeOrderSaga() {
  try {
    const ordersById: string = yield select((state: IRootReducerState) => ({
      ...state.ordersReducer.current.ordersById
    }));
    const order_id: string = yield select(
      (state: IRootReducerState) => state.ordersReducer.current.order_id
    );
    notify.show(
      i18n.t(Strings.warningMessage, {
        ns: "orderingBoard",
        order_code: ordersById[order_id].order_code
      }),
      "warning",
      10000
    );
    yield delay(2000);
    yield put(clearPrevState());
  } catch (error) {
    yield put(revokeOrderFailure(error));
  }
}
export function* watchRevokeOrderSaga() {
  yield takeLatest(revokeOrderAction.fulfilled, revokeOrderSaga);
}
