export const newOrder = "newOrder";
export const preparing = "preparing";
export const ready = "ready";
export const newest = "newest";
export const oldest = "oldest";
export const id = "id";
export const shopixTitle = "shopixTitle";
export const deliveredTo = "deliveredTo";
export const branch = "branch";
export const inReview = "inReview";
export const outForDelivery = "outForDelivery";
export const readyForPickup = "readyForPickup";
export const paid = "paid";
export const paymentOnArrival = "paymentOnArrival";
export const payment = "payment";
export const delivered = "delivered";
export const pickedUp = "pickedUp";
export const late = "late";
export const min = "min";
export const mins = "mins";
export const ago = "ago";
export const all = "all";
export const title = "title";
export const warningMessage = "warningMessage";
