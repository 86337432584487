import { reducer, on } from "ts-action";
import { toggleModifierGroup } from "../actions/menuActions";
import { toggleModifierGroupAction } from "../../constants/actions";
import { withLoadingReducer } from "./withLoadingState";
import { IModifierGroup } from "./lib";
export const modifierGroupReducer = withLoadingReducer<IModifierGroup>(
  reducer<IModifierGroup>(
    [
      on(toggleModifierGroup, state => ({
        ...state,
        enabled: Number(!state.enabled)
      }))
    ],
    {
      ar_name: "",
      en_name: "",
      enabled: 0,
      id: ""
    }
  ),
  toggleModifierGroupAction
);
