export enum PrivilegedStatus {
  canceled_by_cashier = "canceled_by_cashier",
  out_for_delivery = "out_for_delivery",
  delivered = "delivered",
  ready_for_courier_pickup = "ready_for_courier_pickup"
}

export enum FinalOrderStatus {
  delivered = "delivered",
  got_picked_up = "got_picked_up",
  rejected = "rejected",
  canceled_by_customer = "canceled_by_customer",
  canceled_by_cashier = "canceled_by_cashier",
  canceled_by_courier = "canceled_by_courier",
  canceled_by_agent = "canceled_by_agent",
  ready_for_courier_pickup = "ready_for_courier_pickup"
}

export enum OrderStatus {
  sent = "sent",
  received = "received",
  seen = "seen",
  accepted = "accepted",
  ready_for_pickup = "ready_for_pickup",
  got_picked_up = "got_picked_up",
  rejected = "rejected",
  canceled_by_customer = "canceled_by_customer",
  canceled_by_cashier = "canceled_by_cashier",
  canceled_by_agent = "canceled_by_agent",
  out_for_delivery = "out_for_delivery",
  delivered = "delivered",
  canceled_by_courier = "canceled_by_courier",
  rejected_for_payment_failure = "rejected_for_payment_failure"
}

export enum ChangeableStatus {
  received = "received",
  seen = "seen",
  accepted = "accepted",
  rejected = "rejected",
  canceled_by_cashier = "canceled_by_cashier",
  delivered = "delivered",
  out_for_delivery = "out_for_delivery",
  ready_for_pickup = "ready_for_pickup",
  got_picked_up = "got_picked_up",
  ready_for_courier_pickup = "ready_for_courier_pickup"
}

export enum OrderStatusChange {
  accepted = "accept",
  rejected = "reject",
  canceled_by_cashier = "cancel",
  delivered = "delivered",
  out_for_delivery = "out-for-delivery",
  ready_for_pickup = "ready-for-pickup",
  got_picked_up = "got-picked-up"
}

export enum OrderType {
  delivery = "delivery",
  pickup = "pickup"
}
export enum SocketConnectionStatus {
  // Initial state. No event is emitted in this state.",
  initialized = "initialized",
  // All dependencies have been loaded
  // and Channels is trying to connect.
  // The connection will also enter this state
  // when it is trying to reconnect after a connection failure.",
  connecting = "connecting",
  // The connection to Channels is open
  // and authenticated with your app.",
  connected = "connected",
  // The connection is temporarily unavailable.
  // In most cases this means that there is no internet connection.
  // It could also mean that Channels is down, or some intermediary
  // is blocking the connection. In this state,
  // pusher-js will automatically retry the connection every 15 seconds.
  unavailable = "unavailable",
  // Channels is not supported by the browser.
  // This implies that WebSockets are not natively
  // available and an HTTP-based transport could not be found.",
  failed = "failed",
  // The Channels connection was previously
  // connected and has now intentionally been closed."
  disconnected = "disconnected"
}

export interface IReasons {
  id: number;
  ar_text: string;
  en_text: string;
  is_deleted?: 1 | 0;
}
