import React, { useCallback, useMemo, useState } from "react";
import * as styles from "./styles.module.sass";
import { ModifierGroup } from "./ModifierGroup";
import { IModifierGroup } from "../../../../redux/reducers/lib";
import { prefixOrdering } from "../../../../../../Utils/helpers";
import {
  MenuEntityAvailability,
  toggleItem
} from "../../../../redux/actions/menuActions";
import { LoadingStatus } from "../../../../redux/reducers/withLoadingState";
import { useSelect } from "../../../../../helpers/use-select";
import { useRtlClass } from "../../../../../lib";
import { useTranslation } from "../../../../hooks/useTranslation";
import * as Strings from "../../../../i18n/strings/menuItem";
import { useTranslatedName } from "../../util";
import { useGetCurrency } from "../../../PastOrders/OrderItem/PastOrdersSingleCategoryItem/PastOrdersModifierGroup/PastOrdersModifierItem";
import { GenericButton } from "../../../../Shared-Components/GenericButtonWithIcon";
import ConfirmModal from "./ConfirmModal";
import { useDispatch } from "react-redux";
import { useRecoilValue } from "recoil";
import { selectedMenuBranchAtom } from "../../atoms/selectedMenuBranch";

export interface ISingleMenuItemProps {
  imgPath?: string;
  desc: string;
  price: string;
  id: string;
  status: MenuEntityAvailability;
  modifierGroups: Array<{ group: IModifierGroup; modifiers: string[] }>;
  parentEnabled: boolean;
  whiteMode?: boolean;
  handleModifierItemChange: Function;
}
const stopPropagation = (e: React.SyntheticEvent) => e.stopPropagation();

const defaultPic = (
  <div className={styles.ItemDefault}>
    <img
      style={{ padding: "34px" }}
      className={styles.default}
      src={require("../../../../assets/food.svg")}
    />
  </div>
);

export const SingleMenuItem: React.FC<ISingleMenuItemProps> = props => {
  const {
    imgPath,
    desc,
    whiteMode,
    price,
    status,
    modifierGroups,
    id,
    parentEnabled,
    handleModifierItemChange
  } = props;
  const dispatch = useDispatch();

  const { itemsById } = useSelect(state => state.menuReducer);
  const item = itemsById[id];

  const isEnabled = useMemo(
    () => status === MenuEntityAvailability.enabled && parentEnabled,
    [parentEnabled, status]
  );

  const isHidden = useMemo(() => status === MenuEntityAvailability.hidden, [
    status
  ]);

  const itemPic = (
    <div className={styles.ItemImg}>
      <img src={prefixOrdering(imgPath)} />
    </div>
  );
  const [iconClick, setIconClick] = React.useState(false);
  const branch_id = useRecoilValue(selectedMenuBranchAtom);
  const handleChange = useCallback(
    (newStatus: MenuEntityAvailability) => {
      dispatch(
        toggleItem({
          branch_id,
          id,
          status: newStatus
        })
      );
    },
    [id, branch_id]
  );
  const handleOpenCollapse = e => {
    e.stopPropagation();
    setIconClick(!iconClick);
  };

  const ModifierItemsArray = modifierGroups.map(({ group, modifiers }) => (
    <ModifierGroup
      whiteMode={whiteMode}
      id={group.id}
      key={group.id}
      group={group}
      active={Boolean(group.enabled)}
      parentEnabled={isEnabled}
      items={modifiers}
      handleModifierItemChange={handleModifierItemChange}
    />
  ));

  const { t } = useTranslation("menuItem");
  const [open, setOpen] = useState(false);
  const openHandler = () => setOpen(true);
  const closeHandler = () => setOpen(false);
  return (
    <div
      className={`${
        whiteMode ? styles.whiteModeContent : styles.content
      } ${useRtlClass(styles)}`}
    >
      <div className={styles.main} onClick={handleOpenCollapse}>
        <div className={styles.LeftCorner}>
          <div
            className={`${styles.left} ${
              isEnabled
                ? whiteMode
                  ? styles.whiteModeLeftActive
                  : styles.left_active
                : whiteMode
                  ? styles.whiteModeLeftInActive
                  : styles.left_inactive
            }`}
          >
            <img
              className={
                iconClick && isEnabled ? styles.imgCLick : styles.imgUnCLick
              }
              src={
                whiteMode
                  ? require("../../../../../CallCenter-App/assets/arrow-point-to-down.svg")
                  : require("../../../../assets/arrow-point-to-down.svg")
              }
            />
          </div>
          <div className={styles.body}>
            {imgPath ? itemPic : defaultPic}
            <div className={styles.dta}>
              {!isEnabled && (
                <span className={styles.is_available}>
                  {t(isHidden ? Strings.hidden : Strings.notAvailable)}
                </span>
              )}
              <div className={styles.itemData}>
                <span className={styles.name}>{useTranslatedName(item)}</span>
                <p className={styles.desc}>{desc}</p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.RightCorner}>
          <div className={styles.price}>
            <span>
              {useGetCurrency()} {price}
            </span>
            <div onClick={stopPropagation}>
              <GenericButton
                iconPath={require("../../../../../CallCenter-App/assets/settings.svg")}
                className={styles.settingsWrapper}
                disabled={item.loadingStatus === LoadingStatus.loading}
                onClick={openHandler}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles.itemContent} ${
          iconClick && isEnabled
            ? styles.itemContentEnable
            : styles.itemContentDisable
        }`}
      >
        {ModifierItemsArray}
      </div>
      {open && (
        <ConfirmModal
          initialValue={status}
          onSelectOption={handleChange}
          open={open}
          closeHandler={closeHandler}
        />
      )}
    </div>
  );
};
