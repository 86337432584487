import { takeLatest, select, call } from "redux-saga/effects";
import * as actions from "../actions/menuActions";
import { toggleItemAction } from "../../constants/actions";
import { selectOrderingMenuDB, selectToken } from "../../../redux-store/selectors";
import { union } from "ts-action";
import { changeItemAvailability } from "../../axios/changeItemAvailability";
import { handleOptimism } from "./handleOptimism";

const actionType = union({ toggleItem: actions.toggleItem });
function* toggleItemSaga(action: typeof actionType) {
  const token = yield select(selectToken);
  const orderingMenuDBInstance = yield select(selectOrderingMenuDB);
  yield call(
    changeItemAvailability,
    token,
    action.payload.branch_id,
    action.payload.id,
    action.payload.status
  );
  yield call(async () => {
    const value = await orderingMenuDBInstance?.items.get(action.payload.id);
    const category_value = await orderingMenuDBInstance?.categories.get(value?.category_id);
    if (category_value?.enabled) {
      await orderingMenuDBInstance?.items.update(action.payload.id, { status: action.payload.status.includes("available") ? "enabled" : action.payload.status })
      await orderingMenuDBInstance?.modifiers_groups.where({ item_id: action.payload.id }).modify({ enabled: action.payload.status.includes("available") ? 1 : 0 })
    }
  })
  return action.payload;
}

export function* watchToggleItemSaga() {
  yield takeLatest(
    toggleItemAction.requested,
    handleOptimism(toggleItemSaga, {
      failure: actions.toggleItemFailure,
      success: actions.toggleItemSuccess
    })
  );
}
