import { reducer, on } from "ts-action";
import { toggleCategory } from "../actions/menuActions";
import { toggleCategoryAction } from "../../constants/actions";
import { withLoadingReducer } from "./withLoadingState";
import { ICategory } from "./lib";

export const categoryItemReducer = withLoadingReducer<ICategory>(
  reducer<ICategory>(
    [
      on(toggleCategory, state => ({
        ...state,
        enabled: Number(!state.enabled)
      }))
    ],
    { ar_name: "", en_name: "", enabled: 0, id: "" }
  ),
  toggleCategoryAction
);
