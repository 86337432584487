import { rejectOrderAction } from "../../constants/actions";
import { put, select, call, takeLatest } from "redux-saga/effects";
import { union } from "ts-action";
import { selectToken } from "../../../redux-store/selectors";
import { rejectOrder } from "../../axios/rejectOrder";
import {
  rejectOrders,
  rejectOrderFailure,
  rejectOrderSuccess
} from "../actions/ordersActions";
const actionType = union({ rejectOrders });

function* rejectOrderSaga(action: typeof actionType) {
  try {
    const token = yield select(selectToken);
    const { reason_id, order_id } = action.payload;
    const res = yield call(rejectOrder, token, order_id, reason_id);
    yield put(rejectOrderSuccess(res.data));
  } catch (e) {
    yield put(rejectOrderFailure(e));
  }
}

export function* watchRejectOrderSaga() {
  yield takeLatest(rejectOrderAction.requested, rejectOrderSaga);
}
