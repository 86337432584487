import Switch, { ReactSwitchProps } from "react-switch";
import * as styles from "./styles.module.sass";
import React from "react";
import { useRtlClass } from "../../../../lib";
const stopPropagation = (e: React.MouseEvent) => {
  e.stopPropagation();
};

export const SettingsSwitch = (props: ReactSwitchProps) => {
  return (
    <Switch
      onHandleColor="#fff"
      handleDiameter={18}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0 1px 4px 0 rgba(0, 0, 0, 0.1)"
      activeBoxShadow="0 1px 4px 0 rgba(0, 0, 0, 0.1)"
      height={20}
      width={37}
      onColor="#f09440"
      {...props}
      onClick={stopPropagation}
      className={`${styles.settingSwitch} ${props.className ||
        ""} ${useRtlClass(styles)}`}
    />
  );
};
