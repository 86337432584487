import axios from "./axios";
import * as Request from "./requests";
import { IOrder } from "../redux/reducers/lib";
import { OrderStatus } from "../constants/types";

export const getOrders = (token: string, order_status: OrderStatus[]) => {
  return axios.get<{ orders: IOrder[] }>(Request.getOrders, {
    params: {
      order_status
    },
    headers: {
      token
    }
  });
};
