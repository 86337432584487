import axios from "./axios";
import { CategoryAvailability } from "./requests";
import { MenuEntityAvailability } from "../redux/actions/menuActions";
import { ChangeableAvailability } from "./changeItemAvailability";

export const changeCategoryAvailability = (
  token: string,
  branch_id: string,
  id: string,
  availability: MenuEntityAvailability
) => {
  return axios.put(
    CategoryAvailability + "/" + id,
    { availability: ChangeableAvailability[availability], branch_id },
    {
      headers: {
        token
      }
    }
  );
};
