import React, { useCallback } from "react";
import * as styles from "./styles.module.sass";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import { searchForMenuItem } from "../../../../../redux/actions/menuActions";
import { hot } from "react-hot-loader";
import { useTranslation } from "../../../../../hooks/useTranslation";
import * as Strings from "../../../../../i18n/strings/SearchForProduct";
import { useRtlClass } from "../../../../../../lib";

export interface ISearchForProductProps {
  NumberOfItems: number;
  whiteMode?: boolean;
}

const Component: React.FC<ISearchForProductProps> = props => {
  const dispatch = useDispatch();

  const search = useCallback(
    debounce((term: string) => {
      const scrollPanel = document.querySelector(".scroll-panel");
      if (scrollPanel) {
        scrollPanel.scroll({ behavior: "smooth", left: 0, top: 0 });
      }
      dispatch(searchForMenuItem(term));
    }, 300),
    [dispatch]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      search(e.target.value);
    },
    [search]
  );
  const { t } = useTranslation("SearchForProduct");
  return (
    <div
      className={`${
        props.whiteMode ? styles.whiteWrapper : styles.main
      } ${useRtlClass(styles)}`}
    >
      <div
        className={`${
          props.whiteMode ? styles.whiteMenuName : styles.menuName
        }`}
      >
        {t(Strings.MenuTitle)} ({props.NumberOfItems})
      </div>
      <div className={styles.Search}>
        <div
          className={`${
            props.whiteMode ? styles.whiteSearchWrapper : styles.searchWrapper
          }`}
        >
          <input
            onChange={handleChange}
            type="text"
            placeholder={t(Strings.SearchForProductPlaceholder)}
          />
          <SearchIcon nativeColor="#b2b2b2" />
        </div>
      </div>
    </div>
  );
};

export const SearchForProduct = hot(module)(Component);
