import * as ModelTypes from "../data-model/types";
import {
  IAddPointsOperation,
  IBranch,
  ICashier,
  ICustomer,
  IRedeemRewardOperation,
  IStoreItem
} from "../data-model/types";
import { ON_OFFLINE, ON_ONLINE } from "../constants/netEvents";
import * as constants from "../constants";

import { CashierWebDB } from "../db/CashierWebDB";

export interface IAppStoreState {
  countries?: ICountry[];
  token?: string;
  cashier?: ModelTypes.ICashier;
  cashiers?: ModelTypes.ICashier[];
  store?: ModelTypes.IStore;
  userActive: boolean;
}
export type SyncState = 0 | 1 | -1;
export interface ISyncReducerState {
  syncState: SyncState;
  networkState: NetworkState;
  last_updated_time: number;
}

export interface INetworkStateReducerState {
  networkState: NetworkState;
}

export type NetworkState = typeof ON_ONLINE | typeof ON_OFFLINE;

export interface ISyncSuccessResponse {
  cashier?: ModelTypes.ICashier;
  cashiers?: ModelTypes.ICashier[];
  store?: ModelTypes.IStore;
  branches?: ModelTypes.IBranch[];
  categories?: ModelTypes.ICategory[];
  items?: ModelTypes.IStoreItem[];
  customers?: ModelTypes.ICustomer[];
  last_updated_time: number;
  add_points_operations?: ModelTypes.IAddPointsOperation[];
  redeem_reward_operations?: ModelTypes.IRedeemRewardOperation[];
  failed_activities?: ModelTypes.IFailedActivity[];
  gift_operations?: ModelTypes.IRedeemGiftInboxOperation[];
}

export interface ISyncAppAction {
  type: string;
  payload: ISyncData;
}
export interface ISyncSuccessResponseAction {
  type: string;
  payload: ISyncSuccessResponse;
}

export interface ISyncFailureResponseAction {
  type: string;
  payload: any;
}

export interface IOpenDBAction {
  type: string;
}
export interface IOpenDBSuccessAction {
  type: string;
  payload: CashierWebDB;
}

// export interface IOpenDBErrorAction {
//   type: string,
//   payload: Error,
// }

export type SyncAction =
  | ISyncAppAction
  | ISyncSuccessResponseAction
  | ISyncFailureResponseAction
  | INetworkChangeAction;

export type SyncOperation = "add";
export type SyncService = "points";

export interface ISyncActivity {
  service: SyncService;
  timestamp: number;
  operation: SyncOperation;
  data: {
    id: string;
    shopxId?: string; // or phoneNumber,countryCode
    phoneNumber?: string;
    countryCode?: string;
    receipt?: number;
    receipt_code?: string;
    call_center?: 1 | undefined; // if it is pointsOperation and came from call center
    branch_id?: string; // if it is pointsOperation and came from call center
    // and we will assign this operation to the above branch
  };
}

export interface IPendingSyncActivity extends ISyncActivity {
  customer: ICustomer | string;
  data: {
    id: string;
    shopxId?: string; // or phoneNumber,countryCode
    phoneNumber?: string;
    countryCode?: string;
    receipt?: number;
    receipt_code?: string;
    call_center?: 1 | undefined; // if it is pointsOperation and came from call center
    branch_id?: string; // if it is pointsOperation and came from call center
    // and we will assign this operation to the above branch
  };
}

export interface ISyncData {
  activities?: ISyncActivity[];
}

export interface INetworkChangeAction {
  type: string;
  payload: NetworkState;
}

export interface IListenToNetworkChangeAction {
  type: string;
}

export interface ILoginResponse {
  token: string;
  call_center?: 1;
  cashier_code: number;
  id: string;
  store_pos?: string | null;
  store_id: string;
}
export interface ISwitchAccountResponse {
  data: ILoginResponse;
  status: number;
}
export interface IRedeemResponse {
  code: string;
}
export interface IVerifyResponse {
  status: number;
  customer: ICustomer;
  item: IStoreItem;
}
export interface ILoginAction {
  type: typeof constants.loginAction.requested;
}

export interface ILoginSuccessAction {
  type: typeof constants.loginAction.fulfilled;
  payload: ILoginResponse;
}

export interface ILoginFailureAction {
  type: typeof constants.loginAction.fulfilled;
  payload: Error;
}

export interface ISetSelectedCashierBranchAction {
  type: typeof constants.ACTION_SET_SELECTED_CASHIER_BRANCH;
  payload: IBranch;
}

export type IAuthAction =
  | ILoginAction
  | ILoginSuccessAction
  | ILoginFailureAction;

export type CashierTable =
  | CashierWebDB["AddPointsOperation"]
  | CashierWebDB["Branch"]
  | CashierWebDB["Cashier"]
  | CashierWebDB["Category"]
  | CashierWebDB["Customer"]
  | CashierWebDB["Store"]
  | CashierWebDB["RedeemRewardOperation"]
  | CashierWebDB["StoreItem"];

export interface IAddSyncActivityAction {
  type: typeof constants.addSyncActivityAction.requested;
  payload: ISyncActivity;
}

export interface IAddSyncActivityActionSuccess {
  type: typeof constants.addSyncActivityAction.fulfilled;
  payload: IPendingSyncActivity;
}

export interface IAddSyncActivityActionFailure {
  type: typeof constants.addSyncActivityAction.rejected;
  payload: Error;
}

export interface ICountry {
  code: string;
  ISO_code: string;
  icon: string;
  en_name: string;
  ar_name: string;
}

export interface IGetCountriesAction {
  type: typeof constants.getCountriesAction.requested;
  payload: { search?: string; page?: number };
}

export interface IGetCountriesSuccessAction {
  type: typeof constants.getCountriesAction.fulfilled;
  payload: ICountry[];
}

export interface IGetCountriesFailureAction {
  type: typeof constants.addSyncActivityAction.rejected;
  payload: Error;
}

export interface IVerifyCodeAction {
  type: typeof constants.verifyCodeAction.requested;
  payload: string;
}

export interface IVerifyCodeSuccessAction {
  type: typeof constants.verifyCodeAction.fulfilled;
  payload: IVerifyResponse;
}

export interface IVerifyCodeFailureAction {
  type: typeof constants.verifyCodeAction.rejected;
  payload: Error;
}

export interface IRedeemGiftAction {
  type: typeof constants.redeemGiftsAction.requested;
  payload: {
    code: string;
    cb: () => void;
  };
}

export interface IRedeemGiftSuccessAction {
  type: typeof constants.redeemGiftsAction.fulfilled;
  payload: IRedeemResponse;
}

export interface IRedeemGiftFailureAction {
  type: typeof constants.redeemGiftsAction.rejected;
  payload: Error;
}

export interface ICurrentAccountAction {
  type: typeof constants.currentAccountAction.requested;
  payload: ICashier;
}

export interface IGetAccountsAction {
  type: typeof constants.getAccountsAction.requested;
}

export interface IGetAccountsSuccessAction {
  type: typeof constants.getAccountsAction.fulfilled;
  payload: ICashier[];
}

export interface IGetAccountsFailureAction {
  type: typeof constants.getAccountsAction.rejected;
  payload: Error;
}

export interface ISwitchAccountAction {
  type: typeof constants.switchAccountAction.requested;
  payload: ICashier;
}

export interface ISwitchAccountSuccessAction {
  type: typeof constants.switchAccountAction.fulfilled;
  payload: ILoginResponse;
}

export interface ISwitchAccountFailureAction {
  type: typeof constants.switchAccountAction.rejected;
  payload: Error;
}

export interface ILogoutAction {
  type: typeof constants.logoutAction.requested;
}

export interface ILogoutSuccessAction {
  type: typeof constants.logoutAction.fulfilled;
}

export interface ILogoutFailureAction {
  type: typeof constants.logoutAction.rejected;
  payload: Error;
}

export interface IResetRedeemAction {
  type: typeof constants.ACTION_RESET_REDEEM;
}
export interface ILogAddPointsAction {
  type: typeof constants.logAddPointsAction.requested;
}

export interface ILogAddPointsSuccessAction {
  type: typeof constants.logAddPointsAction.fulfilled;
  payload: Array<IAddPointsOperation | ISyncActivity>;
}

export interface ILogAddPointsFailureAction {
  type: typeof constants.logAddPointsAction.rejected;
  payload: Error;
}
export interface ILogAddPointsForComparisonSuccessAction {
  type: typeof constants.logAddPointsForComparisonAction.fulfilled;
  payload: IAddPointsOperation[];
}

export interface ILogRedeemPointsAction {
  type: typeof constants.logRedeemPointsAction.requested;
}

export interface ILogRedeemPointsSuccessAction {
  type: typeof constants.logRedeemPointsAction.fulfilled;
  payload: IRedeemRewardOperation[];
}

export interface ILogRedeemPointsFailureAction {
  type: typeof constants.logRedeemPointsAction.rejected;
  payload: Error;
}

export enum IService {
  pickup = "pickup",
  delivery = "delivery",
  ordering = "ordering"
}

export enum ToggleOrderingStatusType {
  pause = "pause",
  re_enable = "re-enable"
}

export type appLanguages = "ar" | "en";
