import { OrderingMenuDB } from '../../db/orderingMenuDb';
import * as constants from '../../constants/actions';
import { IOpenOrderingMenuDBSuccessAction } from '../actions/orderingMenuDBActions';

export interface IDBReducerState {
  db: OrderingMenuDB | null;
  dbLoaded: boolean
}

const initialState: IDBReducerState = {
  db: null,
  dbLoaded: false,
};

const orderingMenuDBReducer = (
  state: IDBReducerState = initialState,
  action: IOpenOrderingMenuDBSuccessAction
): IDBReducerState => {
  switch (action.type) {
    case constants.openOrderingMenuDBAction.fulfilled: {
      const db = action.payload;
      return {
        db,
        dbLoaded: true,
      };
    }
    default:
      return state;
  }
};


export default orderingMenuDBReducer;
