import React, { useEffect } from "react";
import * as styles from "./styles.module.sass";
import * as singleItemsStyles from "./SingleItem/styles.module.sass";
import {
  ScrollAbleCategoryTitle,
  IScrollAbleCategoryProps
} from "./SingleItem";
import debounce from "lodash/debounce";
import { hot } from "react-hot-loader";
import { useRtlClass } from "../../../../../lib";

export interface IItemsNavigatorProps {
  SingleItems: IScrollAbleCategoryProps[];
  whiteMode?: boolean;
}

const scrollActiveLinkIntoView = debounce(() => {
  const element = document.querySelector(
    `.${singleItemsStyles.whiteActive}`
  ) as HTMLAnchorElement;
  element.scrollIntoView({
    behavior: "smooth",
    inline: "start",
    block: "center"
  });
}, 100);

const Component: React.FC<IItemsNavigatorProps> = props => {
  // this effect is to scroll the selected category into view once it was selected
  useEffect(() => {
    const scrollPanel = document.querySelector(
      ".scroll-panel"
    ) as HTMLDivElement;
    scrollPanel.addEventListener("scroll", scrollActiveLinkIntoView);
    return () =>
      scrollPanel.removeEventListener("scroll", scrollActiveLinkIntoView);
  }, []);

  const items = props.SingleItems.map((item, index) => (
    <ScrollAbleCategoryTitle
      key={index}
      to={item.to}
      item={item.item}
      isScrolled={item.isScrolled}
      whiteMode={props.whiteMode}
    />
  ));
  return (
    <div
      className={`${
        props.whiteMode ? styles.whiteWrapper : styles.wrapper
      } ${useRtlClass(styles)}`}
    >
      <div className={`${styles.nav}`}>
        {items}
        <div style={{ minWidth: "92px" }} />
      </div>
      <div className={styles.ellipsis} />
    </div>
  );
};

export const ItemsNavigator = hot(module)(Component);
