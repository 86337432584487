import * as React from "react";
import * as stringDirection from 'string-direction';
import {useMemo} from "react";

export const DirectedText: React.FC<React.HTMLAttributes<HTMLSpanElement> & {children: string}> = ({children,...otherProps}) => {
  const direction = useMemo(() => {
    return stringDirection.getDirection(children.substring(0, 1));
  }, [children]);
  return (
    <span {...otherProps as any} style={{direction}}>
      {children}
    </span>
  )
};
