import axios from "./axios";
import * as Requests from "./requests";
import { AxiosReturn } from "./types";
import { ILoginResponse } from "../types";

export const login = (
  phone_number_or_email: string,
  password: string
): AxiosReturn<ILoginResponse> => {
  return axios.post(Requests.login, {
    phone_number_or_email,
    password
  });
};
