import React, { useState, useCallback, useMemo } from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { Modal } from "@material-ui/core";
import RadioButtonField from "../../../../../Shared-Components/RadioButtonField/index";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { GenericButtonWithoutIcon } from "../../../../../Shared-Components/GenericButtonWithoutIcon";
import { useRtlClass } from "../../../../../../lib";
import * as Strings from "../../../../../i18n/strings/menu";
import { MenuEntityAvailability } from "../../../../../redux/actions/menuActions";
interface IProps {
  open: boolean;
  closeHandler: () => void;
  onSelectOption: (selectedStatus: MenuEntityAvailability) => void;
  initialValue: MenuEntityAvailability;
}

const ConfirmModal: React.FC<IProps> = props => {
  const { open, closeHandler, onSelectOption } = props;
  const { t } = useTranslation("menu");
  const [state, setState] = useState<MenuEntityAvailability>(
    props.initialValue
  );

  const handleSelectState = useCallback(
    e => {
      setState(e.target.value);
    },
    [state]
  );

  const handleSubmit = useCallback(
    () => {
      onSelectOption(state);
      closeHandler();
    },
    [onSelectOption, state]
  );

  const options = useMemo(() => {
    return [
      {
        icon: require("../../../../../../CallCenter-App/assets/nice.svg"),
        iconClass: styles.iconWrapper,
        title: t(Strings.itemAvailable),
        radioValue: MenuEntityAvailability.enabled
      },
      {
        icon: require("../../../../../../CallCenter-App/assets/24.svg"),
        iconClass: styles.clockWrapper,
        title: t(Strings.itemIsSoldOut),
        description: t(Strings.itemIsSoldOutDescription),
        radioValue: MenuEntityAvailability.sold_out_all_day
      },
      {
        icon: require("../../../../../../CallCenter-App/assets/clockMenu.svg"),
        iconClass: styles.iconWrapper,
        title: t(Strings.itemIsRunOutIndefinitely),
        radioValue: MenuEntityAvailability.sold_out_indefinitely,
        description: t(Strings.itemIsRunOutIndefinitelyDescription)
      },
      {
        icon: require("../../../../../../CallCenter-App/assets/hide.svg"),
        iconClass: styles.hideWrapper,
        title: t(Strings.hideItemFromTheBranch),
        description: t(Strings.hideItemFromTheBranchDescription),
        radioValue: MenuEntityAvailability.hidden
      }
    ];
  }, []);

  const enabledItemNextStates = useMemo(
    () => [options[1], options[2], options[3]],
    []
  );

  const disabledItemNextStates = useMemo(() => [options[0], options[3]], []);

  const hiddenItemNextStates = useMemo(
    () => [options[0], options[1], options[2]],
    []
  );

  const availableChoices = useMemo(
    () => {
      const map = {
        [MenuEntityAvailability.disabled]: disabledItemNextStates,
        [MenuEntityAvailability.sold_out_all_day]: disabledItemNextStates,
        [MenuEntityAvailability.sold_out_indefinitely]: disabledItemNextStates,
        [MenuEntityAvailability.enabled]: enabledItemNextStates,
        [MenuEntityAvailability.hidden]: hiddenItemNextStates
      };
      return map[props.initialValue];
    },
    [props.initialValue]
  );
  return (
    <Modal open={open} onClose={closeHandler} className={styles.modal}>
      <div className={`${styles.wrapper} ${useRtlClass(styles)}`}>
        <div className={styles.closeWrapper}>
          <span className={styles.title}>{t(Strings.itemSettings)}</span>
          <img
            className={styles.icon}
            onClick={closeHandler}
            src={require("../../../../../assets/close.svg")}
          />
        </div>
        {availableChoices.map(radio => (
          <RadioButtonField
            checked={state === radio.radioValue}
            key={`radio-${radio.radioValue}`}
            id={`radio-${radio.radioValue}`}
            {...radio}
            onChange={handleSelectState}
          />
        ))}

        <div className={styles.btnWrapper}>
          <GenericButtonWithoutIcon
            label={t(Strings.saveChanges)}
            onClick={handleSubmit}
            className={styles.save}
          />
        </div>
      </div>
    </Modal>
  );
};

export default hot(module)(ConfirmModal);
