import header from "./header";
export default {
  code: "كود الطلب:",
  timer: "يصل العميل بعد:",
  arrive: "توصيل ل:",
  userName: "اسم المستخدم",
  zoneOrRegion: "المنطقة",
  gotPickedUp: "تم الاستلام",
  total: "إجمالي الدفع",
  cash: "الدفع عند الاستلام",
  shopixTitle:
    "سيتم توصيل الطلب عن طريق كوينز(سوف يقوم مندوب التوصيل باستلام الطلب منك و إيصاله للعميل)",
  branch: "الفرع:",
  cashDelivery: "الدفع عند التوصيل",
  paymentMethod: "طريقة الدفع",
  orderingMethod: "طريقة التوصيل",
  visa: "البطاقة الائتمانية",
  reject: "رفض",
  accept: "قبول",
  orderTime: "وقت الطلب",
  cancel: "إلغاء",
  call: '"اتصل بالعميل',
  orderSettings: "اعدادات الطلب",
  prevOrder: "الطلبات السابقة",
  currentOrder: "الطلب الحالي",
  contactSupport: "اطلب الاتصال بالدعم",
  cancellingOrder: "الغاء",
  confirmCancellation: "تأكيد الغاء الطلب",
  save: "حفظ",
  contactClient: "اتصل بالعميل",
  timeAdjustmentTo: "تعديل الوقت الي:",
  preparationTime: "تعديل وقت التحضير",
  clientArrivesAfter: "يصل العميل بعد:",
  preparationButton: "الطلب جاهز؟",
  price: "السعر",
  typeOfRequest: "نوع الطلب",
  number: "العدد",
  comment: "تعليق",
  commentOnRequest: "تعليق علي الطلب",
  newOrders: "الطلبات الجديده",
  lateOrders: "الطلبات المتأخره",
  gift: "هدية",
  selectTheItemState: "اختار حالة نفاذ الصنف",
  soldOutForToday: "نفد الصنف اليوم",
  or: "أو",
  soldOut: "نفد الصنف إلى أجل غير مُسمى",
  orderType: "نوع الطلب",
  totalPrice: "إجمالي الدفع",
  confirmRejectingOrder: "تأكيد رفض الطلب",
  discount: "خصم",
  promo_applied: "يطبق الكود الترويجي",
  totalPriceAfterDiscount: "المجموع النهائي",
  back: "العودة",
  rejectionReason: "يجب اختيار سبب رفض الطلب",
  reason: "السبب:",
  warning: "عند رفض الطلب لايمكن استرجاع الطلب مره اخري",
  c: "السبب:",
  receiveIt: "استلام من الفرع",
  zone: "المنطقة",
  deliveryCost: "التوصيل",
  street: "الشارع",
  totalInvoiceMessage: "لقد وصلك طلب جديد بقيمة {{total_invoice}}",
  buildingNumber: "المبني",
  apartmentNumber: "الشقة",
  specialMark: "علامة مميزة",
  delivery: "توصيل",
  pickup: "استلام من الفرع",
  floor: "الدور",
  readyButton: "الطلب جاهز؟",
  readyDeliveryButton: "خرج للتوصيل؟",
  markAsDelivered: "تم التوصيل؟",
  giftRedemptionCode: "كود استبدال الهدية :",
  dineIn: "الاكل في الفرع",
  takeaway: "تيك اواي",
  tableNumber: "رقم الطاولة",
  ...header
};
