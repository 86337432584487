import { takeLatest, put, select, retry } from "redux-saga/effects";
import { getOrderingStatusAction } from "../../constants/actions";
import * as actions from "../actions/orderingStatusActions";
import { selectToken } from "../../../redux-store/selectors";
import { getBranchOrderingStatus } from "../../axios/getBranchOrderingStatus";
import { AxiosResponse } from "axios";
import { OrderingStatus } from "../../components/AvailableRequests/AvailableRequestsModal";
import { getStoreOrderingStatus } from "../../axios/getStoreOrderingStatus";
import { receiveStoreOrderingStatusChange } from "../actions/socket-actions";

export const defaultRetry = <Fn extends (...args: any[]) => any>(
  fn: Fn,
  ...args: Parameters<Fn>
) => {
  return retry(5, 10, fn, ...args);
};

function* getOrderingStatusSaga() {
  try {
    const token = yield select(selectToken);
    type statusRes = AxiosResponse<{
      pickup_status: OrderingStatus;
      delivery_status: OrderingStatus;
    }>;
    const branchRes: statusRes = yield defaultRetry(
      getBranchOrderingStatus,
      token,
      "branch_id"
    );
    const storeRes: statusRes = yield defaultRetry(
      getStoreOrderingStatus,
      token,
      "branch_id"
    );
    const storeStatus = storeRes.data.pickup_status;
    yield put(receiveStoreOrderingStatusChange({ pickup_status: storeStatus }));
    yield put(actions.getOrderingStatusSuccess(branchRes.data));
  } catch (e) {
    yield put(actions.getOrderingStatusFailure(""));
  }
}

export function* watchGetOrderingStatusSaga() {
  yield takeLatest(getOrderingStatusAction.requested, getOrderingStatusSaga);
}
