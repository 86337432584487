import { Middleware, MiddlewareAPI, Dispatch } from "redux";
import { initSocket } from "./socket";
import { ACTION_AUTHENTICATE_SOCKET } from "../constants/actions";
import { union } from "ts-action";
import Pusher from "pusher-js";
import * as actions from "../redux/actions/socket-actions";
import { SocketConnectionStatus } from "../constants/types";
import storeOrdersChannelEventsActions from "./storeOrdersChannelEventsActions";
import laborChannelEventsActions from "./laborChannelEventsActions";
import storeChannelEventsActions from "./storeChannelEventsActions";
import { IRootReducerState } from "../../redux-store/rootReducer";
import { logoutAction } from "../../constants";
import { changeNetworkState } from "../../redux-store/actions";
import { ON_OFFLINE, ON_ONLINE } from "../../constants/netEvents";

export const delay = (timeout = 100) => {
  return new Promise(res => setTimeout(() => res(), timeout));
};

const disconnectSocket = async (
  socket: Pusher.Pusher,
  storeAPI: MiddlewareAPI<any, IRootReducerState>
) => {
  const { cashier_store_id: store_id, id } = storeAPI.getState().authReducer;
  socket?.unbind_all();
  socket?.connection?.unbind_all();
  socket?.connection?.disconnect();
  await delay();

  socket?.unsubscribe(
    `presence-store_orders.${store_id}`
  );
  socket?.unsubscribe(
    `presence-store-pickup-status.${store_id}`
  );

  socket?.unsubscribe(
    `presence-call_center_cashier_orders.${id}`
  );

  await delay();

  socket?.disconnect();
  await delay();
};

const configureSocket = (
  socket: Pusher.Pusher,
  storeAPI: MiddlewareAPI<any, IRootReducerState>
) => {
  socket.connection.bind(
    "state_change",
    (states: { current: SocketConnectionStatus }) => {
      switch (states.current) {
        case SocketConnectionStatus.connected:
        case SocketConnectionStatus.connecting:
          storeAPI.dispatch(changeNetworkState(ON_ONLINE));
          break;
        case SocketConnectionStatus.unavailable:
        case SocketConnectionStatus.failed:
          storeAPI.dispatch(changeNetworkState(ON_OFFLINE));
      }
      storeAPI.dispatch(
        actions.changeConnectionStatus({ status: states.current })
      );
    }
  );

  const { cashier_store_id: store_id, id } = storeAPI.getState().authReducer;
  const storeOrdersChannel = socket.subscribe(
    `presence-store_orders.${store_id}`
  );
  const storeChannel = socket.subscribe(
    `presence-store-pickup-status.${store_id}`
  );

  const laborDistributionChannel = socket.subscribe(
    `presence-call_center_cashier_orders.${id}`
  );
  // const channel = socket.subscribe(`my-channel`);

  laborChannelEventsActions.forEach(eventMeta => {
    laborDistributionChannel.bind(eventMeta.label, data => {
      storeAPI.dispatch(eventMeta.actionCreator(data));
    });
  });
  storeOrdersChannelEventsActions.forEach(eventMeta => {
    storeOrdersChannel.bind(eventMeta.label, data => {
      storeAPI.dispatch(eventMeta.actionCreator(data));
    });
  });
  storeChannelEventsActions.forEach(eventMeta => {
    storeChannel.bind(eventMeta.label, data => {
      storeAPI.dispatch(eventMeta.actionCreator(data));
    });
  });

  return {
    storeOrdersChannel,
    storeChannel,
    laborDistributionChannel
  };
};

const actionTypes = union(actions);
type SocketAction = typeof actionTypes;

export const createMySocketMiddleware = (): Middleware<
  {},
  IRootReducerState
> => {
  let socket: Pusher.Pusher;
  let channels: {
    storeOrdersChannel: Pusher.Channel;
    storeChannel: Pusher.Channel;
  };
  return storeAPI => {
    return (next: Dispatch<SocketAction>) => async (action: SocketAction) => {
      if (!channels && action.type === ACTION_AUTHENTICATE_SOCKET) {
        const { token } = storeAPI.getState().authReducer;
        socket = initSocket(token);
        channels = configureSocket(socket, storeAPI);
        return;
      }
      if (action.type === logoutAction.requested) {
        if (socket && channels) {
          channels?.storeOrdersChannel?.unbind_all();
          channels?.storeChannel?.unbind_all();
          await disconnectSocket(socket, storeAPI);
        }
      }
      return next(action);
    };
  };
};
