import { MenuEntityAvailability } from "../../../redux/actions/menuActions";
import React, { useCallback } from "react";
import Modal from "@material-ui/core/Modal";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { useTranslation } from "../../../hooks/useTranslation";
import * as Strings from "../../../i18n/strings/newOrder";
export type handleSelectItem = (status: MenuEntityAvailability) => void;
interface IProps {
  onSelect: handleSelectItem;
  open: boolean;
  closeModal: () => void;
}

export const MarkEntitySoldOutContext = React.createContext<{
  open: (fn: handleSelectItem) => void;
  openModifierGroup: (fn: handleSelectItem) => void;
  openModifierItem: (fn: handleSelectItem) => void;
}>({
  open: (fn: handleSelectItem) => null,
  openModifierGroup: (fn: handleSelectItem) => null,
  openModifierItem: (fn: handleSelectItem) => null
});

const Component: React.FC<IProps> = ({ onSelect, open, closeModal }) => {
  const { t } = useTranslation("newOrder");

  const handleSelect = useCallback(
    (status: MenuEntityAvailability) => {
      onSelect(status);
      closeModal();
    },
    [onSelect]
  );

  const handleClickIndefinitely = useCallback(
    () => handleSelect(MenuEntityAvailability.sold_out_indefinitely),
    [handleSelect]
  );
  const handleClickAllDay = useCallback(
    () => handleSelect(MenuEntityAvailability.sold_out_all_day),
    [handleSelect]
  );

  return (
    <Modal
      open={open}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <div className={styles.wrapper}>
        <img
          src={require("../../../assets/close.svg")}
          onClick={closeModal}
          className={styles.close}
        />
        <h4 className={styles.title}>{t(Strings.selectTheItemState)}</h4>
        <div className={styles.optionsWrapper}>
          <button className={styles.optionButton} onClick={handleClickAllDay}>
            {t(Strings.soldOutForToday)}
          </button>
          <span className={styles.buttonsSeparator}>{t(Strings.or)}</span>
          <button
            className={styles.optionButton}
            onClick={handleClickIndefinitely}
          >
            {t(Strings.soldOut)}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export const MarkItemSoldOutPopup = hot(module)(Component);
