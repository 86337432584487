import { action, payload } from "ts-action";
import { contactSupportAction } from "../../constants/actions";

export const contactSupport = action(contactSupportAction.requested);
export const contactSupportSuccess = action(
  contactSupportAction.fulfilled,
  payload<string>()
);
export const contactSupportFailure = action(
  contactSupportAction.rejected,
  payload<Error>()
);
