import React from "react";
import { useSelect } from "../../helpers/use-select";

export const RemoteLoginBanner: React.FC = () => {
  const { isLoggedInRemotely } = useSelect(state => state.authReducer);
  return isLoggedInRemotely ? <RemoteLoginContent /> : null;
};

export const RemoteLoginContent: React.FC = () => {
  const { store } = useSelect(state => state.storeReducer);
  // const { selected_branch } = useSelect(state => state.authReducer);
  return (
    <div
      style={{
        width: "100%",
        height: "7.2rem",
        fontSize: "2.4rem",
        color: "#fff",
        backgroundColor: "#41126c",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      Live Preview from: ({store?.name}) branch
    </div>
  );
};
