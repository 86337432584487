import * as actions from "../actions/ordersActions";
import { union } from "ts-action";
import { select, call, takeEvery } from "redux-saga/effects";
import { selectToken } from "../../../redux-store/selectors";
import { changeOrderStatus } from "../../axios/changeOrderStatus";
import { handleOptimism } from "./handleOptimism";
import { ChangeableStatus } from "../../../Pickup-App/constants/types";
import { dispatchOrderAPI } from "../../../Pickup-App/axios/dispatchFleetOrderAPI";

const actionType = union({ a: actions.changeOrderStatus });

function* changeOrderStatusSaga(action: typeof actionType) {
  const token = yield select(selectToken);
  const { payload } = action;
  if (payload.newStatus === ChangeableStatus.ready_for_courier_pickup) {
    yield call(dispatchOrderAPI, token, { order_id: payload.id });
  } else {
    yield call(changeOrderStatus, token, payload.id, payload.newStatus);
  }
  return action.payload;
}

export function* watchChangeOrderStatusSaga() {
  yield takeEvery(
    actions.changeOrderStatus.type,
    handleOptimism(changeOrderStatusSaga, {
      failure: actions.changeOrderStatusFailure,
      success: actions.changeOrderStatusSuccess
    })
  );
}
