import * as React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { useSelect } from "../../../helpers/use-select";
import { selectOrdersVariations } from "../../redux/reducers/getOrdersReducer";
import { useTranslation } from "../../hooks/useTranslation";
import Strings from "../../i18n/strings/lockscreen";
import { withRouter, RouteComponentProps } from "react-router-dom";
// import { deriveOrderingStatus } from "../../redux/reducers/orderingStatus";
// import { OrderingStatus } from "../AvailableRequests/AvailableRequestsModal";
import { ON_OFFLINE } from "../../../constants/netEvents";
import * as StoreStatusStrings from "../../i18n/strings/StoreStatus";
import { useDispatch } from "react-redux";
import { OrderStatus } from "../../constants/types";
import { changeOrdersGroupStatus } from "../../redux/actions/ordersActions";
import { offlineDismiss } from "../../redux/actions/lockScreenActions";
import { useEffect } from "react";
// import debounce from "lodash/debounce";
enum LockScreenStates {
  newOrders = "newOrders",
  disconnected = "disconnected",
  pausedAutomatically = "pausedAutomatically"
}

const LockScreen: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation("lockscreen");
  const { pendingNewOrders, lateNewOrders } = useSelect(selectOrdersVariations);
  const { ordersById } = useSelect(state => state.ordersReducer);
  const { userActive, offline_dismiss } = useSelect(
    state => state.lockScreenReducer
  );
  const { networkState } = useSelect(state => state.networkStateReducer);
  // const orderingStatusState = useSelect(state => state.orderingStatus);

  // const orderingStatus = React.useMemo(
  //   () => deriveOrderingStatus(orderingStatusState),
  //   [orderingStatusState]
  // );
  const count = pendingNewOrders.length + lateNewOrders.length;

  const currentLockScreenStatus = React.useMemo(() => {
    let state: LockScreenStates | undefined = !!count
      ? LockScreenStates.newOrders
      : undefined;
    // state =
    //   orderingStatus === OrderingStatus.pausedForInactivity
    //     ? LockScreenStates.pausedAutomatically
    //     : state;
    state = networkState === ON_OFFLINE ? LockScreenStates.disconnected : state;
    return state;
  }, [
    // orderingStatus,
    networkState,
    count
  ]);
  const showOfflineLockScreen =
    currentLockScreenStatus === LockScreenStates.disconnected &&
    !offline_dismiss;
  const lockScreenShown =
    showOfflineLockScreen || (!userActive && currentLockScreenStatus);

  const stateDetails = React.useMemo(() => {
    const statesData = {
      [LockScreenStates.newOrders]: {
        iconContent: count,
        headLineContent: t(Strings.newOrders),
        iconBgColor: "#f6bc87",
        screenBgColor: "#f09440"
      },
      [LockScreenStates.disconnected]: {
        iconContent: <img src={require("../../assets/wifi.svg")} />,
        headLineContent: t(StoreStatusStrings.NoConnectionReason, {
          ns: "StoreStatus"
        }),
        iconBgColor: "#e15151",
        screenBgColor: "#db3030"
      },
      [LockScreenStates.pausedAutomatically]: {
        iconContent: <img src={require("../../assets/test.svg")} />,
        headLineContent: t(StoreStatusStrings.LateReplyReason, {
          ns: "StoreStatus"
        }),
        iconBgColor: "#e15151",
        screenBgColor: "#db3030"
      }
    };
    return currentLockScreenStatus && statesData[currentLockScreenStatus];
  }, [currentLockScreenStatus, count]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (
      lockScreenShown &&
      (currentLockScreenStatus === LockScreenStates.newOrders ||
        currentLockScreenStatus === LockScreenStates.disconnected)
    ) {
      let time: number;
      const audioObj = new Audio(require("../../assets/twin.aac").default);
      const audioPlaying = () => {
        audioObj.play();
        time = window.setTimeout(() => {
          audioObj.pause();
        }, 120000);
      };
      audioObj.addEventListener("canplay", audioPlaying);
      audioObj.loop = true;
      return () => {
        audioObj.pause();
        clearTimeout(time);
        audioObj.removeEventListener("canplay", audioPlaying);
      };
    }
    return undefined;
  }, [lockScreenShown, currentLockScreenStatus]);

  useEffect(() => {
    if (!lockScreenShown) {
      const unSeenOrders = [...lateNewOrders, ...pendingNewOrders].filter(
        orderId =>
          ordersById[orderId].status === OrderStatus.received ||
          ordersById[orderId].status === OrderStatus.sent
      );
      if (unSeenOrders.length) {
        dispatch(
          changeOrdersGroupStatus({
            orderIds: unSeenOrders,
            status: OrderStatus.seen
          })
        );
      }
    }
  }, [lockScreenShown]);
  const handleDismissing = () => {
    if (offline_dismiss === false) {
      dispatch(offlineDismiss(true));
    }
  };
  return lockScreenShown ? (
    <div
      className={styles.wrapper}
      onClick={handleDismissing}
      style={{ backgroundColor: stateDetails?.screenBgColor }}
    >
      <div
        className={styles.ordersCount}
        style={{ backgroundColor: stateDetails?.iconBgColor }}
      >
        <div
          className={styles.innerCircle}
          style={{ backgroundColor: stateDetails?.screenBgColor }}
        >
          {stateDetails?.iconContent}
        </div>
      </div>
      <div className={styles.title}>{stateDetails?.headLineContent}</div>
      <div className={styles.subTitle}>{t(Strings.dismissText)}</div>
    </div>
  ) : null;
};

export default hot(module)(withRouter(LockScreen as any));
