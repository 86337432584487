import React from "react";
import * as styles from "./styles.module.sass";
import { SearchForProduct } from "./ItemsNavigator/SearchForProduct";
import { ItemsNavigator } from "./ItemsNavigator";
import { IScrollAbleCategoryProps } from "./ItemsNavigator/SingleItem";

interface IMainHeaderProps {
  number: number;
  items: IScrollAbleCategoryProps[];
  whiteMode?: boolean;
}

export const MainHeader: React.FC<IMainHeaderProps> = props => {
  return (
    <div className={props.whiteMode ? styles.mainWhite : styles.main}>
      <SearchForProduct
        NumberOfItems={props.number}
        whiteMode={props.whiteMode}
      />
      <ItemsNavigator SingleItems={props.items} whiteMode={props.whiteMode} />
    </div>
  );
};
