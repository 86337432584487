import React from "react";
import * as styles from "./styles.module.sass";
import { GenericButtonWithoutIcon } from "../../../../Shared-Components/GenericButtonWithoutIcon";
import { useTranslation } from "../../../../hooks/useTranslation";
import * as Strings from "../../../../i18n/strings/newOrder";
import { storedOrderType } from "../../../../redux/reducers/getOrdersReducer";
import { useGetCurrency } from "../../../PastOrders/OrderItem/PastOrdersSingleCategoryItem/PastOrdersModifierGroup/PastOrdersModifierItem";
import {
  IGetOrderDetailsResponse,
  PaymentMethods
} from "../../../../axios/getOrderDetails";
import OrderSettings, {
  OrderSettingsScreens
} from "../../../NewOrders/OrderDetails/UserInfo/OrderSettings";
import { useToggleState } from "../../../../../../Utils/custom-hooks/useToggleState";
import { OrderType } from "../../../../constants/types";
import { useRtlClass } from "../../../../../lib";
import { IOrder } from "../../../../redux/reducers/lib";
import Big from "big.js";
import { totalInvoice, fleetTotalInvoice } from "./lib";
import { DeliveryMethod } from "../../../../../data-model/types";
import { OrderStatus } from "../../../../constants/types";
export const useCalculateOrderTotal = (order: IOrder) => {
  return React.useMemo(
    () => {
      return calculateOrderTotal(order);
    },
    [order]
  );
};

export const calculateOrderTotal = (order: IOrder) => {
  return Big(order.total_invoices || 0).toFixed(2);
};

export const useCalculateOrderTotalWithoutPromo = (order: IOrder) => {
  return React.useMemo(
    () => {
      return (
        order.total_invoices_without_promo_code &&
        order.total_invoices_without_promo_code
      );
    },
    [order]
  );
};

interface IProps {
  onClose: () => void;
  order: storedOrderType;
  orderDetails: IGetOrderDetailsResponse;
}
const PaymentDetails: React.FC<IProps> = ({ onClose, order }) => {
  const { sent, seen, received } = OrderStatus;
  const beforeAcceptStatus = { sent, seen, received };
  const { t } = useTranslation("newOrder");

  // const createHandler = useCallback(
  //   (status: OrderStatus) => () => {
  //     dispatch(changeOrderStatus({ id: order.id, newStatus: status }));
  //     onClose();
  //   },
  //   [order.id]
  // );
  const [confirmRejectOpen, toggleRejectConfirmOpen] = useToggleState();

  // useCallback(createHandler(OrderStatus.rejected), [
  //   createHandler
  // ]);

  return (
    <>
      <div className={styles.paymentWrapper}>
        <div className={`${useRtlClass(styles)} ${styles.wrapper}`}>
          <div className={styles.payment}>
            <span className={styles.paymentMethod}>{t(Strings.total)} </span>
            <span className={styles.cash}>
              {useGetCurrency()}{" "}
              {order.delivery_method !== DeliveryMethod.third_party_delivery
                ? totalInvoice(order)
                : fleetTotalInvoice(order)}
            </span>
          </div>
          <div className={styles.total}>
            <span className={styles.totalTitle}>
              {" "}
              {t(Strings.paymentMethod)}{" "}
            </span>
            <span className={styles.price}>
              {order.order_type === OrderType.delivery
                ? order.payment_method === PaymentMethods.cash
                  ? t(Strings.cashDelivery)
                  : t(Strings.visa)
                : order.payment_method === PaymentMethods.cash
                  ? t(Strings.cash)
                  : t(Strings.visa)}
            </span>
          </div>
        </div>
        {order.status in beforeAcceptStatus && (
          <div className={styles.rejectWrapper}>
            <GenericButtonWithoutIcon
              label={t(Strings.cancellingOrder)}
              className={styles.reject}
              onClick={toggleRejectConfirmOpen}
            />
          </div>
        )}
      </div>
      {confirmRejectOpen && (
        <OrderSettings
          order={order}
          initialScreen={OrderSettingsScreens.rejectOrder}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default PaymentDetails;
