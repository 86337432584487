import Notification from "rc-notification";
import * as React from "react";
import * as styles from "./styles.module.sass";
import { useRtlClass } from "../../../lib";

let notification;
Notification.newInstance({}, n => (notification = n));

export interface ICustomNotificationsOptions {
  content: React.ReactElement<any>;
  key?: string;
  closable?: boolean;
  onClose?: () => void;
  duration?: number;
  style?: any;
  maxCount?: number;
  closeIcon?: React.ReactNode;
}

const notifyCustomContent = (options: ICustomNotificationsOptions) => {
  notification.notice({
    duration: 30,
    style: {
      position: "absolute",
      bottom: "32px",
      width: "100%",
      left: "26px",
      transition: "all 1s",
      opacity: 1
    },
    onClose() {
      return
    },
    ...options
  });
};

export const GenericNotification: React.FC<{
  message: string;
  icon: string;
  className: string;
}> = ({ className, icon, message }) => {
  return (
    <div className={`${styles.wrapper} ${useRtlClass(styles)} ${className}`}>
      <img src={icon} />
      <span>{message}</span>
    </div>
  );
};

export const PickUpErrorUI: React.FC = ({ children }) => {
  return (
    <GenericNotification
      message={children as string}
      className={styles.failure}
      icon={require("./assets/error.svg")}
    />
  );
};
const error = (content: string, duration = 5000) => {
  notifyCustomContent({
    duration: duration / 1000,
    content: <PickUpErrorUI>{content}</PickUpErrorUI>
  });
};

const success = (content: string, duration = 5000) => {
  notifyCustomContent({
    duration: duration / 1000,
    content: (
      <GenericNotification
        message={content}
        className={styles.success}
        icon={require("./assets/success.svg")}
      />
    )
  });
};

export const customPickupNotify = {
  error,
  success
};
