import * as React from "react";
import styles from "./styles.module.sass";
import { ModifierItem } from "./ModifierItem";
import { SettingsSwitch } from "../../../SittingSwitch";
import { toggleModifierGroup } from "../../../../../redux/actions/menuActions";
import { LoadingStatus } from "../../../../../redux/reducers/withLoadingState";
import { useSelect } from "../../../../../../helpers/use-select";
import { useTranslation } from "../../../../../hooks/useTranslation";
import * as Strings from "../../../../../i18n/strings/menuItem";
import { IModifierGroup } from "../../../../../redux/reducers/lib";
import {
  useTranslatedName,
  useHandleModifierGroupEnabled
} from "../../../util";
import { atom, useRecoilState } from "recoil";
export interface IModifierGroupProps {
  group: IModifierGroup;
  active: boolean;
  parentEnabled: boolean;
  items: string[];
  whiteMode?: boolean;
  id: string;
  handleModifierItemChange: Function;
}

export const modifierGroupState = atom({
  default: false,
  key: "modifierGroup"
});
export const ModifierGroup: React.FC<IModifierGroupProps> = props => {
  const { modifierItemsById, modifierGroupsById } = useSelect(
    state => state.menuReducer
  );
  const {
    group,
    active: itemActive,
    parentEnabled,
    items,
    id,
    whiteMode,
    handleModifierItemChange
  } = props;
  const active = itemActive && parentEnabled;
  const handleChange = useHandleModifierGroupEnabled(
    parentEnabled,
    active,
    id,
    toggleModifierGroup
  );
  const [modifierGroupActive, setModifierGroupActive] = useRecoilState(
    modifierGroupState
  );
  const ModifierItems = items.map(modifierItemId => {
    const item = modifierItemsById[modifierItemId];
    return (
      <ModifierItem
        key={modifierItemId}
        id={modifierItemId}
        name={item.name}
        ar_name={item.ar_name}
        en_name={item.en_name}
        whiteMode={whiteMode}
        price={item.price}
        parentEnabled={active}
        active={Boolean(item.enabled)}
        handleModifierItemChange={handleModifierItemChange}
      />
    );
  });

  const { t } = useTranslation("menuItem");
  const is_available = (
    <span className={styles.is_available}>{t(Strings.notAvailable)}</span>
  );
  React.useEffect(
    () => {
      if (active) {
        setModifierGroupActive(active);
      }
    },
    [active, modifierGroupActive]
  );
  return (
    <div className={`${whiteMode ? styles.whiteModeMain : styles.main}`}>
      <div className={styles.header}>
        <div className={styles.name}>
          {useTranslatedName(group)}
          {!active && is_available}
        </div>
        <div className={styles.toggle}>
          <SettingsSwitch
            disabled={
              modifierGroupsById[id].loadingStatus === LoadingStatus.loading
            }
            className={styles.switch}
            onChange={handleChange}
            checked={active}
          />
        </div>
      </div>
      <div className={styles.body}>{ModifierItems}</div>
    </div>
  );
};
