export default {
  newOrder: "جديد",
  preparing: "جاري التحضير",
  ready: "جاهز",
  newest: "الاحدث",
  oldest: "الاقدم",
  id: "الكود",
  deliveredTo: "توصيل الي",
  branch: "الفرع",
  outForDelivery: "خرج للتوصيل",
  shopixTitle:
    "سيتم توصيل الطلب عن طريق كوينز(سوف يقوم مندوب التوصيل بالستلام الطلب منك و إيصاله للعميل)",
  readyForPickup: "جاهز للاستلام",
  paid: "خالص الدفع",
  payment: ":الدفع",
  paymentOnArrival: "عند الاستلام/التوصيل",
  delivered: "تم التوصيل",
  pickedUp: "تم الاستلام",
  late: "متأخر",
  min: "دقيقة",
  mins: "دقائق",
  ago: "مضت",
  all: "الكل",
  title: "الفرع:",
  warningMessage: "تم سحب الطلب رقم {{order_code}} لتأخرك في الاستجابة",
  inReview: "في المراجعة"
};
