import { on, reducer } from "ts-action";
import { IBranch } from "../../../data-model/types";
import { getBranchesSuccess } from "../actions/branches";
import { withLoadingReducer } from "../../../Pickup-App/redux/reducers/withLoadingState";
import { getBranchesAction } from "../../../constants";
import { OrderingStatus } from "../../../Pickup-App/components/AvailableRequests/AvailableRequestsModal";
import { toggleOrderingStatusSuccess } from "../../../Pickup-App/redux/actions/orderingStatusActions";
import { IService, ToggleOrderingStatusType } from "../../../types/index";

interface IStatuses {
  pickup_status: OrderingStatus;
  delivery_status: OrderingStatus;
  store_pickup_status: OrderingStatus;
  store_delivery_status: OrderingStatus;
}
interface IState {
  branches: string[];
  branchesById: { [x: string]: IBranch & IStatuses };
}

export const branchesReducer = withLoadingReducer(
  reducer<IState>(
    [
      on(getBranchesSuccess, (state, { payload }) => ({
        ...state,
        branches: payload
          .filter(a => a.call_center === undefined)
          .map(b => b.id),
        branchesById: payload.reduce(
          (acc, next) => ({
            ...acc,
            [next.id]: next
          }),
          {}
        )
      })),
      on(toggleOrderingStatusSuccess, (state, { payload }) => {
        const oldBranch = state.branchesById[payload.branch_id];
        const newStatus =
          payload.type === ToggleOrderingStatusType.pause
            ? OrderingStatus.pausedByCashier
            : OrderingStatus.enabled;
        return {
          ...state,
          branchesById: {
            ...state.branchesById,
            [payload.branch_id]: {
              ...oldBranch,
              delivery_status:
                payload.service === IService.delivery
                  ? newStatus
                  : oldBranch.delivery_status,
              pickup_status:
                payload.service === IService.pickup
                  ? newStatus
                  : oldBranch.pickup_status
            }
          }
        };
      })
    ],
    { branches: [], branchesById: {} }
  ),
  getBranchesAction
);
