import { OrderStatus } from "../../constants/types";

export default {
  orderStatues: "Order Status",
  orderCode: "Order ID",
  clientName: "Customer Name",
  orderPrice: "Total",
  rejected: "Rejected",
  got_picked_up: "Picked up",
  canceled_by_customer: "Canceled by customer",
  canceled_by_cashier: "Canceled",
  canceled_by_courier: "Canceled",
  canceled_by_agent: "Canceled",
  allStatuses: "All statuses",
  searchByCodePlaceholder: "Search by order code",
  [OrderStatus.out_for_delivery]: "Out for delivery",
  pastOrdersTitle: "Past orders ({{count}})",
  pastOrders: "Past orders",
  branch: "Branch:",
  orderType: "Order type",
  [OrderStatus.delivered]: "Delivered"
};
