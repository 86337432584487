import { changeOrderStatus } from "./changeOrderStatus";
import { ChangeableStatus } from "../constants/types";

export const rejectOrder = (
  token: string,
  order_id: string,
  reason_id?: number
) => {
  return changeOrderStatus(
    token,
    order_id,
    ChangeableStatus.rejected,
    reason_id
  );
};
