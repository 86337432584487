import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { CustomDropDownSelect } from "../../../Pickup-App/components/AvailableRequests/AvailableRequestsModal/CustomDropDownSelect";
import { useSelect } from "../../../helpers/use-select";
import { ISelectItemData } from "../../../Pickup-App/components/AvailableRequests/AvailableRequestsModal/RenderDropDownSelect";
import { useDispatch } from "react-redux";
import { getBranches } from "../../react-redux/actions/branches";
import * as Strings from "../../i18n/strings/orderingBoard";
import { useTranslation } from "../../../Pickup-App/hooks/useTranslation";
import { IBranch } from "../../../data-model/types/index";
import { LoadingStatus } from "../../../Pickup-App/redux/reducers/withLoadingState";
import { useRtlClass } from "../../../lib";
import { selectOrderingMenuDB } from "../../../redux-store/selectors";
import { useRecoilState } from "recoil";
import { selectedMenuBranchAtom } from "../../../Pickup-App/components/Menu/atoms/selectedMenuBranch";
interface IProps {
  onChange: (item: ISelectItemData) => void;
  onBranchesLoaded?: (branches: IBranch[]) => void;
  useFirstAsDefault?: boolean;
}
const SelectBranch: React.FC<IProps> = ({
  onChange,
  onBranchesLoaded,
}) => {
  const dispatch = useDispatch();
  const orderingMenuDBInstance = useSelect(selectOrderingMenuDB);
  const [selectedBranchId, setSelectedBranchId] = useRecoilState(
    selectedMenuBranchAtom
  );
  const { branches, branchesById, loadingStatus } = useSelect(
    state => state.branchesReducer
  );
  const [orderingMenuBranchIDLoadingStatus, setOrderingMenuBranchIDLoadingStatus] = useState<boolean>(false)
  useEffect(() => {
    dispatch(getBranches());
  }, []);

  // useEffect(() => {
  //   if (
  //     loadingStatus === LoadingStatus.success &&
  //     branches.length &&
  //     onBranchesLoaded
  //   ) {
  //     onBranchesLoaded(branches.map(b => branchesById[b]));
  //   }
  // }, [branches, loadingStatus, branchesById]);

  const { t } = useTranslation("orderingBoard");

  const branchesOptions = (branches: any[]) => {
    const options = [
      { value: undefined, label: t(Strings.all) },
      ...branches.map(
        (branch): ISelectItemData => ({
          value: branchesById[branch].id,
          label: branchesById[branch].name
        })
      )
    ];
    return options;
  };
  const getSelectedBarnchIdFromDB = async (branches) => {
    setOrderingMenuBranchIDLoadingStatus(true);
    const data = await orderingMenuDBInstance?.schedulers.toArray();
    setOrderingMenuBranchIDLoadingStatus(false);
    if (data?.length) { return setSelectedBranchId(data?.[0].branch_id); }
    else { return setSelectedBranchId(branchesOptions(branches)[1].value); }
  };
  useEffect(() => {
    if (branches.length) { getSelectedBarnchIdFromDB(branches); }
  }, [branches]);

  const showSelect = useMemo(() => {
    return branches.length && selectedBranchId
  }, [branches, selectedBranchId]);

  const getInitialValue = (branches) => {
    const value = selectedBranchId
      ? branchesOptions(branches).find(
        item => item.value === selectedBranchId
      )
      : branchesOptions(branches)[branches.length ? 1 : 0];
    return value;
  }

  const renderSelect = (item: ISelectItemData) => (
    <div className={styles.selectWrapper}>
      <span className={styles.labelSelected}>{item.label}</span>
    </div>
  );
  const renderToggle = (item: ISelectItemData) => (
    <div className={styles.filterWrapper}>
      <span className={styles.label}>
        {!item?.label ? t(Strings.all) : item.label}
      </span>
      <img src={require("../../../assets/dropdown.svg")} />
    </div>
  );
  const rtl = useRtlClass(styles);
  return (
    <div className={`${styles.wrapper} ${rtl}`}>
      {showSelect && (
        <>
          <span className={styles.dropDownTitle}>{t(Strings.title)}</span>
          <CustomDropDownSelect
            initialValue={
              getInitialValue(branches)
            }
            options={branchesOptions(branches)}
            onChange={onChange}
            renderSelectToggle={renderToggle}
            renderSelectItem={renderSelect}
          />
        </>
      )}
    </div>
  );
};

export default hot(module)(SelectBranch);
