import axios from "./axios";
import * as Requests from "./requests";
import { MenuEntityAvailability } from "../redux/actions/menuActions";

export interface IGetMenuResponse {
  categories: ICategory[];
}

interface ICategory {
  id: string;
  ar_name?: string;
  en_name?: string;
  enabled: number;
  items: IItem[];
}

interface IItem {
  id: string;
  ar_name?: string;
  en_name?: string;
  price: string;
  description: string;
  image: string;
  status: MenuEntityAvailability;
  modifiers_groups: IModifiersgroup[];
}

interface IModifiersgroup {
  id: string;
  ar_name?: string;
  en_name?: string;
  enabled: number;
  modifiers: IModifier[];
}

interface IModifier {
  id: string;
  name: string;
  en_name: string;
  ar_name: string;
  enabled: number;
  price: string;
}

export const getMenuAPI = (token: string, branch_id: string) => {
  return axios.get<IGetMenuResponse>(Requests.getMenu, {
    headers: {
      token
    },
    params: {
      branch_id
    }
  });
};
