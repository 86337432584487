import { OrderingMenuDB } from "../db/orderingMenuDb";

export const clearOrderingMenuDB = async (orderingMenuDBInstance :OrderingMenuDB ) => {
    await orderingMenuDBInstance?.schedulers.clear();
    await orderingMenuDBInstance?.items.clear();
    await orderingMenuDBInstance?.categories.clear();
    await orderingMenuDBInstance?.modifiers.clear();
    await orderingMenuDBInstance?.modifiers_groups.clear();
  }

  export const  orderingMenuDBSetter  = async (orderingMenuDBInstance :OrderingMenuDB  , itemsById  : any[], categoriesById : any[] , modifierItemsById : any[] , modifierGroupsById : any[] , branch_id) => {
    await orderingMenuDBInstance?.items.bulkAdd(Object.values(itemsById));
    await orderingMenuDBInstance?.categories.bulkAdd(Object.values(categoriesById));
    await orderingMenuDBInstance?.modifiers.bulkAdd(Object.values(modifierItemsById));
    await orderingMenuDBInstance?.modifiers_groups.bulkAdd(Object.values(modifierGroupsById));
    await orderingMenuDBInstance?.schedulers.put({ id: 1, hydrated_at: Date.now() , branch_id });

  }