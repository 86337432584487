import Pusher from "pusher-js";

export const initSocket = (token: string) => {
  // socket.subscribe(`presence-cashier_orders.${branch_id}`);
  return new Pusher(process.env.PUSHER_API_KEY as string, {
    authEndpoint: process.env.PICKUP_ENDPOINT + "/api/pusher/call-center/auth",
    auth: {
      headers: {
        token
      }
      // params: {
      //   branch_id
      // }
    },
    cluster: "eu"
  });
};
