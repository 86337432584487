import register from "../plugin-sw-precache/register-service-worker";
import * as React from "react";
import * as ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.sass";
import persistedStore, { isDev } from "./redux-store/configureStore";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
// import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";
import AppSelector from "./components/AppSelector";
import Notifications from "react-notify-toast";
import { LoadingBar } from "react-redux-loading-bar";
import("./fonts.sass");
import JssProvider from "react-jss/lib/JssProvider";
import createGenerateClassName from "@material-ui/core/styles/createGenerateClassName";
import { HashRouter, Route } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import { RemoteLogin } from "./components/RemoteLogin/index";
import { Switch } from "react-router";
import { PersistGate } from "redux-persist/integration/react";
import { RemoteLoginBanner } from "./components/RemoteLoginBanner/index";
import { Integrations } from "@sentry/tracing";
import LogRocket from "logrocket";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
const firebaseConfig = {
  apiKey: "AIzaSyAyxR1qo-pYg2us7G2HOqg3sIy_VJLzI8E",
  authDomain: "cashier-web-app.firebaseapp.com",
  databaseURL: "https://cashier-web-app.firebaseio.com",
  projectId: "cashier-web-app",
  storageBucket: "cashier-web-app.appspot.com",
  messagingSenderId: "31502395959",
  appId: "1:31502395959:web:6c333127b787d077cc6db2"
};

firebase.initializeApp(firebaseConfig);

register();
if (!isDev) {
  import("@sentry/browser").then(({ init }) => {
    // let options: typeof sentry.default.
    init({
      // @ts-ignore
      dsn:
        "https://ab23fadb3bfc41c4a32e29a752788d81@o154581.ingest.sentry.io/5605152",
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0
    });
  });
  LogRocket.init("yc9uah/koin-call-center");
}

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: "cashier"
});

export async function initApp() {
  const { store, persistor } = persistedStore();
  const queryClient = new QueryClient();
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <RecoilRoot>
            <I18nextProvider i18n={i18n}>
              <JssProvider generateClassName={generateClassName}>
                <>
                  <LoadingBar style={{ backgroundColor: "#f09440" }} />
                  <Notifications options={{ zIndex: 2000 }} />
                  <HashRouter>
                    <RemoteLoginBanner />
                    <Switch>
                      <Route
                        path={"/remote-login"}
                        exact={true}
                        component={RemoteLogin as any}
                      />

                      <AppSelector />
                    </Switch>
                  </HashRouter>
                </>
              </JssProvider>
            </I18nextProvider>
          </RecoilRoot>
        </QueryClientProvider>
      </PersistGate>
    </Provider>,
    document.getElementById("root") as HTMLDivElement
  );
}

initApp();
