import instance from "./axios";
import * as requests from "./requests";

export interface IBranchAvgPrepTime {
  pickup_prep_min: number;
  pickup_prep_max: number;
  branch_id: string;
}

export const branchAveragePrepTimeApi = {
  get: (token: string, branch_id: string) =>
    instance.get<IBranchAvgPrepTime>(requests.branchAvgPrepTime(branch_id), {
      headers: { token }
    }),

  put: (token: string, branch_id: string, data: IBranchAvgPrepTime) =>
    instance.put(requests.branchAvgPrepTime(branch_id), data, {
      headers: {
        token
      }
    })
};
