import * as React from "react";
import styles from "./styles.module.sass";
import { SettingsSwitch } from "../../../../SittingSwitch";
import { toggleModifierItem } from "../../../../../../redux/actions/menuActions";

import { useTranslation } from "../../../../../../hooks/useTranslation";
import * as Strings from "../../../../../../i18n/strings/menuItem";
import {
  useHandleModifierItemEnabled,
  useTranslatedName
} from "../../../../util";
import { useGetCurrency } from "../../../../../PastOrders/OrderItem/PastOrdersSingleCategoryItem/PastOrdersModifierGroup/PastOrdersModifierItem";

export interface IModifierItemProps {
  id: string;
  name: string;
  en_name: string;
  ar_name: string;
  price: string;
  active: boolean;
  parentEnabled: boolean;
  whiteMode?: boolean;
  handleModifierItemChange: Function;
}

export const ModifierItem: React.FC<IModifierItemProps> = props => {
  const {
    price,
    active: itemActive,
    parentEnabled,
    id,
    whiteMode,
    handleModifierItemChange
  } = props;
  const active = itemActive && parentEnabled;
  const handleChange = useHandleModifierItemEnabled(
    parentEnabled,
    active,
    id,
    toggleModifierItem
  );

  const CurrentCurrency = useGetCurrency();

  const { t } = useTranslation("menuItem");
  const is_available = (
    <span className={styles.is_available}>{t(Strings.notAvailable)}</span>
  );
  const name = useTranslatedName(props);
  return (
    <div className={`${whiteMode ? styles.whiteModeContent : styles.content}`}>
      <div className={styles.name}>
        {name}
        {!active && is_available}
      </div>
      <div className={styles.price}>
        <span>
          {CurrentCurrency} {price}.00
        </span>
        <SettingsSwitch
          className={styles.switch}
          onChange={() => {
            handleChange();
            handleModifierItemChange(id);
          }}
          checked={active}
        />
      </div>
    </div>
  );
};
