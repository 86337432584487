import React from "react";
import * as styles from "./similar.styles.module.sass";
import { hot } from "react-hot-loader";
import { Modal } from "@material-ui/core";
import { useTranslation } from "../../../hooks/useTranslation";
import { useRtlClass } from "../../../../lib";
import * as Strings from "../../../i18n/strings/menu";
import { GenericButtonWithoutIcon } from "../../../Shared-Components/GenericButtonWithoutIcon";

interface IProps {
  open: boolean;
  closeHandler: () => void;
  title: string;
  subTitle: string;
  handleSubmit: () => void;
}

const ConfirmSimilarModal: React.FC<IProps> = props => {
  const { open, closeHandler, handleSubmit, title, subTitle } = props;
  const { t } = useTranslation("menu");

  return (
    <Modal open={open} onClose={closeHandler} className={styles.modal}>
      <div className={`${styles.wrapper} ${useRtlClass(styles)}`}>
        <div className={styles.closeWrapper}>
          <span className={styles.title}>{title}</span>
          <img
            className={styles.icon}
            onClick={closeHandler}
            src={require("../../../../assets/close.svg")}
          />
        </div>
        <div className={styles.subTitle}>{subTitle}</div>

        <div className={styles.btnWrapper}>
          <GenericButtonWithoutIcon
            label={t(Strings.cancelSimilarChanges)}
            onClick={closeHandler}
            className={styles.cancel}
          />
          <GenericButtonWithoutIcon
            label={t(Strings.confirmSimilarChanges)}
            onClick={() => {
              handleSubmit();
              closeHandler();
            }}
            className={styles.save}
          />
        </div>
      </div>
    </Modal>
  );
};

export default hot(module)(ConfirmSimilarModal);
