import { useTranslation } from "../../hooks/useTranslation";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import React from "react";
import { MarkEntitySoldOutContext } from "./MarkItemSoldOutPopup";
import { MenuEntityAvailability } from "../../redux/actions/menuActions";
import { ActionCreator } from "ts-action";
import { selectedMenuBranchAtom } from "./atoms/selectedMenuBranch";
import { useRecoilValue } from "recoil";

export interface IBasicTranslatedObject {
  ar_name?: string;
  en_name?: string;
}

const languageMapping: { [x: string]: keyof IBasicTranslatedObject } = {
  ar: "ar_name",
  en: "en_name"
};

export const useTranslatedName = <
  TranslatedItem extends IBasicTranslatedObject
>(
  item: TranslatedItem
) => {
  const { i18n } = useTranslation();
  const currentLanguage = useMemo(() => i18n.language.split("-")[0], [
    i18n.language
  ]);
  return useMemo(
    () => {
      const mappedName = languageMapping[currentLanguage];

      return item[mappedName] || item.en_name || item.ar_name;
    },
    [currentLanguage, item]
  );
};

export const useHandleMenuEnabled = (
  parentEnabled: boolean,
  selfEnabled: boolean,
  id: string,
  actionCreator: ActionCreator<
    any,
    (
      payload: {
        id: string;
        status: MenuEntityAvailability;
        branch_id: string;
      }
    ) => object
  >
) => {
  const branch_id = useRecoilValue(selectedMenuBranchAtom);

  const dispatch = useDispatch();
  const { open } = React.useContext(MarkEntitySoldOutContext);

  const dispatchAction = React.useCallback(
    (status: MenuEntityAvailability) =>
      dispatch(actionCreator({ id, status, branch_id })),
    [id, branch_id]
  );

  return React.useCallback(
    () => {
      if (!parentEnabled) {
        return;
      }
      if (!selfEnabled) {
        dispatchAction(MenuEntityAvailability.enabled);
      } else {
        open(status => {
          dispatchAction(status);
        });
      }
    },
    [parentEnabled, selfEnabled, dispatchAction, open]
  );
};

export const useHandleModifierGroupEnabled = (
  parentEnabled: boolean,
  selfEnabled: boolean,
  id: string,
  actionCreator: ActionCreator<
    any,
    (
      payload: {
        id: string;
        status: MenuEntityAvailability;
        branch_id: string;
      }
    ) => object
  >
) => {
  const branch_id = useRecoilValue(selectedMenuBranchAtom);

  const dispatch = useDispatch();
  const { openModifierGroup } = React.useContext(MarkEntitySoldOutContext);

  const dispatchAction = React.useCallback(
    (status: MenuEntityAvailability) =>
      dispatch(actionCreator({ id, status, branch_id })),
    [id, branch_id]
  );

  return React.useCallback(
    () => {
      if (!parentEnabled) {
        return;
      }
      if (!selfEnabled) {
        dispatchAction(MenuEntityAvailability.enabled);
      } else {
        openModifierGroup(status => {
          dispatchAction(status);
        });
      }
    },
    [parentEnabled, selfEnabled, dispatchAction, openModifierGroup]
  );
};

export const useHandleModifierItemEnabled = (
  parentEnabled: boolean,
  selfEnabled: boolean,
  id: string,
  actionCreator: ActionCreator<
    any,
    (
      payload: {
        id: string;
        status: MenuEntityAvailability;
        branch_id: string;
      }
    ) => object
  >
) => {
  const branch_id = useRecoilValue(selectedMenuBranchAtom);

  const dispatch = useDispatch();
  const { openModifierItem } = React.useContext(MarkEntitySoldOutContext);

  const dispatchAction = React.useCallback(
    (status: MenuEntityAvailability) =>
      dispatch(actionCreator({ id, status, branch_id })),
    [id, branch_id]
  );

  return React.useCallback(
    () => {
      if (!parentEnabled) {
        return;
      }
      if (!selfEnabled) {
        dispatchAction(MenuEntityAvailability.enabled);
      } else {
        openModifierItem(status => {
          dispatchAction(status);
        });
      }
    },
    [parentEnabled, selfEnabled, dispatchAction, openModifierItem]
  );
};
