import axios from "./axios";
import * as Requests from "./requests";
import { OrderStatusForReason } from "./getReasonsForDelivery";
const getStatusReasonAPI = (
  token: string,
  order_status: OrderStatusForReason
) => {
  return axios.get(Requests.getStatusReason, {
    headers: {
      token
    },
    params: {
      order_status
    }
  });
};
export { OrderStatusForReason, getStatusReasonAPI };
