export const SOCKET_EVENT_RECEIVE_NEW_ORDER = "receive_new_order";
export const SOCKET_REVOKE_ORDER = "revoke_order";
export const SOCKET_EVENT_RECEIVE_ORDER_STATUS_CHANGE =
  "receive_order_status_change";
export const SOCKET_EVENT_RECEIVE_ORDERING_STATUS_CHANGE =
  "receive_ordering_status_change";
export const SOCKET_EVENT_RECEIVE_PICKUP_STATUS_CHANGE =
  "receive_pickup_status_change";
export const SOCKET_EVENT_RECEIVE_DELIVERY_STATUS_CHANGE =
  "receive_delivery_status_change";
export const SOCKET_EVENT_RECEIVE_CUSTOMER_ARRIVED = "customer_status_changed";
export const SOCKET_EVENT_RECEIVE_BRANCH_AVERAGE_PREP_TIME_CHANGE =
  "receive_branch_average_prep_time_change";
export const SOCKET_EVENT_ORDER_REVIEWING_STATUS =
  "call_center_order_reviewing_status";
