import ManagePoints from "./ManagePoints/index";
import RedeemGifts from "./RedeemGifts";
import {
  CURRENT_LANG,
  MANAGE_POINTS,
  pointsLog,
  REDEEM_GIFTS,
  redeemLog,
  AR,
  EN
} from "../strings/App";
import Login from "./Login";
import newOrder from "../../Pickup-App/i18n/ar/newOrder";
import Sync from "./Sync";
import logs from "./logs";
import statusNavigator from "../../Pickup-App/i18n/ar/statusNavigator";
import pastOrdersStatues from "../../Pickup-App/i18n/ar/pastOrdersStatues";
import SearchForProduct from "../../Pickup-App/i18n/ar/SearchForProduct";
import StoreStatus from "../../Pickup-App/i18n/ar/StoreStatus";
import callCenterNavigator from "../../CallCenter-App/i18n/ar/callCenterNavigator";
import switchLanguage from "../../CallCenter-App/i18n/ar/switchLanguage";
import menuItem from "../../Pickup-App/i18n/ar/menuItem";
import branchInfo from "../../Pickup-App/i18n/ar/branchInfo";
import callCenterSettings from "../../CallCenter-App/i18n/ar/callCenterSettings";
import contactshopx from "./contactshopx";
import switchAccount from "./switchAccount";
import header from "../../Pickup-App/i18n/ar/header";
import orderingStatus from "../../Pickup-App/i18n/ar/orderingStatus";
import navigation from "../../Pickup-App/i18n/ar/navigation";
import logout from "./logout";
import axios from "./axios";
import navbar from "./navbar";
import selectBranch from "./selectBranch";
import VerifyPasswordModal from "./VerifyPasswordModal";
import * as menu from "../../Pickup-App/i18n/ar/menu";
import lockscreen from "../../Pickup-App/i18n/ar/lockscreen";
import callCenterHeader from "../../CallCenter-App/i18n/ar/callCenterHeader";
import orderingBoard from "../../CallCenter-App/i18n/ar/orderingBoard";

export const ar = {
  translations: {
    [MANAGE_POINTS]: "ادارة النقاط",
    [REDEEM_GIFTS]: "تبديل الجوائز",
    [AR]: "اللغة العربية",
    [EN]: "English",
    [CURRENT_LANG]: "Switch to English",
    [pointsLog]: "سجل النقاط",
    [redeemLog]: "سجل الهدايا"
  },
  [MANAGE_POINTS]: ManagePoints,
  [REDEEM_GIFTS]: RedeemGifts,
  Login,
  axios,
  header,
  Sync,
  logs,
  contactshopx,
  newOrder,
  orderingStatus,
  switchAccount,
  logout,
  navbar,
  selectBranch,
  navigation,
  VerifyPasswordModal,
  menu,
  branchInfo,
  statusNavigator,
  pastOrdersStatues,
  SearchForProduct,
  menuItem,
  StoreStatus,
  lockscreen,
  switchLanguage,
  callCenterNavigator,
  callCenterSettings,
  callCenterHeader,
  orderingBoard
};
