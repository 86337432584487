import { reducer, on } from "ts-action";
import {
  IOrder,
  ICustomerStatus,
  OrderTimingStatus,
  getOrderTimingStatus
} from "./lib";
import { withLoadingReducer, ILoadingState, LoadingStatus } from "./withLoadingState";
import { getOrdersAction } from "../../constants/actions";
import {
  getOrdersSuccess,
  changeOrderTimingStatus,
  changeOrderStatus,
  changeOrdersGroupStatusSuccess,
  startOrderExitAnimation,
  seeUserOrder,
  changeOrderStatusFailure,
  rejectOrders,
  changeOrderStatusSuccess
  // markAsOutForDelivery
} from "../actions/ordersActions";
import {
  receiveNewOrder,
  receiveOrderStatusChange,
  orderReviewingStatusBeforeAcceptance,
  receiveCustomerArrived,
  revokeOrderSuccess,
  clearPrevState
} from "../actions/socket-actions";
import { ChangeableStatus, OrderStatus } from "../../constants/types";
import { IRootReducerState } from "../../../redux-store/rootReducer";
import { createSelector } from "reselect";
import { optimistic, ensureState, OptimisticState } from "redux-optimistic-ui";
import { Reducer } from "redux";

const selectOrders = (state: IRootReducerState) =>
  ensureState(state.ordersReducer).orders;
const selectOrdersStore = (state: IRootReducerState) =>
  ensureState(state.ordersReducer).ordersById;

interface IDerivedCollections {
  ordersInPrep: string[];
  readyOrders: string[];
  arrivedNewOrders: string[];
  lateNewOrders: string[];
  pendingNewOrders: string[];
}

export const selectOrdersVariations = createSelector(
  [selectOrders, selectOrdersStore],
  (orders, ordersById): IDerivedCollections => {
    const onlyNewOrders = orders.filter(id => {
      const { status } = ordersById[id];
      return (
        status === OrderStatus.received ||
        status === OrderStatus.sent ||
        status === OrderStatus.seen
      );
    });
    const lateNewOrders = onlyNewOrders.filter(
      id =>
        getOrderTimingStatus(ordersById[id].sent_at).state ===
        OrderTimingStatus.overdue
    );
    const ordersInPrep = orders.filter(
      id => ordersById[id].status === OrderStatus.accepted
    );
    const pendingNewOrders = onlyNewOrders.filter(id => {
      const order = ordersById[id];
      const { state } = getOrderTimingStatus(order.sent_at);
      return state !== OrderTimingStatus.overdue;
    });
    const readyOrders = orders.filter(id => {
      const { status } = ordersById[id];
      return (
        status === OrderStatus.ready_for_pickup ||
        status === OrderStatus.out_for_delivery
      );
    });
    const arrivedNewOrders = [
      ...onlyNewOrders,
      ...ordersInPrep,
      ...readyOrders
    ].filter(id => ordersById[id].has_arrived);

    return {
      arrivedNewOrders,
      lateNewOrders,
      ordersInPrep,
      pendingNewOrders,
      readyOrders
    };
  }
);

interface IOrdersCollections {
  orders: string[];
  exitingOrders: Set<string>;
  unSeenOrders: Set<string>;
}

interface IOrderTimingStatus {
  orderTimingStatus: OrderTimingStatus;
}

interface IOrdersStores {
  ordersById: { [x: string]: storedOrderType };
  order_id?: string;
}

type IState = IOrdersCollections & IOrdersStores;
interface IOrderLoadingStatus {
  loadingStatus?: LoadingStatus
}

export type storedOrderType = IOrder & ICustomerStatus & IOrderTimingStatus & IOrderLoadingStatus;

const initialState: IState = {
  orders: [],
  ordersById: {},
  exitingOrders: new Set(),
  unSeenOrders: new Set(),
  order_id: "",
};

export const ordersReducer = optimistic(
  withLoadingReducer<IState>(
    reducer<IState>(
      [
        on(revokeOrderSuccess, (state, { payload }) => ({
          ...state,
          orders: state.orders.filter(
            order => state.ordersById[order].id !== payload.id
          ),
          order_id: payload.id
        })),
        on(clearPrevState, (state, {}) => ({
          ...state,
          order_id: ""
        })),
        on(getOrdersSuccess, (state, { payload }) => {
          const sortedOrders = payload.sort((a, b) => a.sent_at - b.sent_at);
          const ordersCollections = sortedOrders.reduce(
            (acc, next) => {
              return {
                ...acc,
                orders: [...acc.orders, next.id],
                ordersById: {
                  ...acc.ordersById,
                  [next.id]: {
                    ...next,
                    orderTimingStatus: getOrderTimingStatus(next.created_at)
                      .state,
                    has_arrived: next.customer_status === "arrived",
                    loadingStatus: LoadingStatus.initial,
                    reviewing_call_center: next.reviewing_call_center
                      ?.cashier_id
                      ? {
                          ...next.reviewing_call_center,
                          status: next.reviewing_call_center?.cashier_id
                            ? "reviewing"
                            : "dismissed"
                        }
                      : undefined
                  }
                }
              };
            },
            {
              orders: [],
              ordersById: {}
            }
          );
          return {
            ...state,
            ...ordersCollections,
            unSeenOrders: new Set(ordersCollections.orders)
          };
        }),
        on(receiveNewOrder, (state, { payload }) => {
          state.unSeenOrders.add(payload.id);
          return {
            ...state,
            orders: Array.from(new Set([...state.orders, payload.id])),
            ordersById: {
              ...state.ordersById,
              [payload.id]: {
                ...payload,
                has_arrived: !!payload.customer_status,
                orderTimingStatus: OrderTimingStatus.justIn,
                loadingStatus: LoadingStatus.initial,
                reviewing_call_center: payload.reviewing_call_center?.cashier_id
                  ? {
                      ...payload.reviewing_call_center,
                      status: payload.reviewing_call_center?.cashier_id
                        ? "reviewing"
                        : "dismissed"
                    }
                  : undefined
              }
            },
            unSeenOrders: new Set(state.unSeenOrders)
          };
        }),

        on(receiveCustomerArrived, (state, { payload }) => {
          const arrivedCustomerOrder = state.ordersById[payload.order_id];
          if (arrivedCustomerOrder) {
            return {
              ...state,
              ordersById: {
                ...state.ordersById,
                [arrivedCustomerOrder.id]: {
                  ...arrivedCustomerOrder,
                  has_arrived: true
                }
              }
            };
          }
          return state;
        }),
        on(orderReviewingStatusBeforeAcceptance, (state, { payload }) => {
          const foundOrder = state.ordersById[payload.order_id];
          if (foundOrder) {
            return {
              ...state,
              ordersById: {
                ...state.ordersById,
                [foundOrder.id]: {
                  ...foundOrder,
                  reviewing_call_center:
                    payload.status === "reviewing"
                      ? {
                          ...payload
                        }
                      : undefined
                }
              }
            };
          }
          return state;
        }),
        on(receiveOrderStatusChange, (state, { payload }) => {
          state.unSeenOrders.add(payload.id);
          const foundOrder = state.ordersById[payload.id];
          if (foundOrder) {
            return {
              ...state,
              ordersById: {
                ...state.ordersById,
                [foundOrder.id]: {
                  ...foundOrder,
                  status: payload.status,
                  cashier_id: payload.changer_id,
                  dispatched_to_third_party_delivery:
                    payload.dispatched_to_third_party_delivery
                }
              },
              unSeenOrders: new Set(state.unSeenOrders)
            };
          }
          return state;
        }),
        on(changeOrderTimingStatus, (state, { payload: { id, newStatus } }) => {
          const oldItem = state.ordersById[id];
          return {
            ...state,
            ordersById: {
              ...state.ordersById,
              [id]: {
                ...oldItem,
                orderTimingStatus: newStatus
              }
            }
          };
        }),
        on(rejectOrders, (state, { payload }) => {
          const exitingOrders = new Set(state.exitingOrders);
          state.unSeenOrders.add(payload.order_id);
          exitingOrders.delete(payload.order_id);
          return {
            ...state,
            exitingOrders,
            ordersById: {
              ...state.ordersById,
              [payload.order_id]: {
                ...state.ordersById[payload.order_id],
                status: OrderStatus.rejected
              }
            },
            unSeenOrders: new Set(state.unSeenOrders)
          };
        }),
        on(changeOrderStatus, (state, {payload}) => {
          return {
            ...state,
            ordersById: {
              ...state.ordersById,
              [payload.id]: {
                ...state.ordersById[payload.id],
                loadingStatus: LoadingStatus.loading
              }
            },
          }
        }),
        on(changeOrderStatusSuccess, (state, { payload }) => {
          return {
            ...state,
            ordersById: {
              ...state.ordersById,
              [payload.id]: {
                ...state.ordersById[payload.id],
                loadingStatus: LoadingStatus.success
              }
            },
          }
        }),
        on(changeOrdersGroupStatusSuccess, (state, { payload }) => {
          return {
            ...state,
            ordersById: {
              ...state.ordersById,
              ...payload.orderIds.reduce(
                (acc, next) => ({
                  ...acc,
                  [next]: {
                    ...state.ordersById[next],
                    status: payload.status
                  }
                }),
                {} as { [x: string]: storedOrderType }
              )
            }
          };
        }),
        on(startOrderExitAnimation, (state, { payload }) => {
          const exitingOrders = new Set(state.exitingOrders);
          state.unSeenOrders.add(payload.order_id);
          exitingOrders.delete(payload.order_id);
          const filteredOrdersById = Object.values(state.ordersById).filter(order => order.id !== payload.order_id).reduce((acc, next) => ({ ...acc, [next.id]: next }), {})
          const filteredOrdersIds = state.orders.filter((orderId) => orderId !== payload.order_id);
          return {
            ...state,
            exitingOrders,
            orders: [ChangeableStatus.delivered, ChangeableStatus.got_picked_up].includes(payload.status) ? filteredOrdersIds : state.orders,
            ordersById: [ChangeableStatus.delivered, ChangeableStatus.got_picked_up].includes(payload.status) ? filteredOrdersById : {
              ...state.ordersById,
              [payload.order_id]: {
                ...state.ordersById[payload.order_id],
                status: payload.status,
                loadingStatus: LoadingStatus.initial
              }
            },
            unSeenOrders: new Set(state.unSeenOrders),
          };
        }),
        on(changeOrderStatusFailure, (state, { payload }) => {
          state.exitingOrders.delete(payload.id);
          return {
            ...state,
            exitingOrders: new Set(state.exitingOrders)
          };
        }),
        on(seeUserOrder, (state, { payload }) => {
          state.unSeenOrders.delete(payload.order_id);
          return {
            ...state,
            unSeenOrders: new Set(state.unSeenOrders)
          };
        })
      ],
      initialState
    ),
    getOrdersAction
  )
) as Reducer<OptimisticState<IState & ILoadingState>>;
