import { takeLatest, select, put } from "redux-saga/effects";
import { ACTION_RECEIVE_NEW_ORDER } from "../../constants/actions";
import { customNotify } from "../../components/NewOrders/Notification";
import { union } from "ts-action";
import { receiveNewOrder } from "../actions/socket-actions";
import { IRootReducerState } from "../../../redux-store/rootReducer";
import { OrderStatus } from "../../constants/types";
import {
  changeOrdersGroupStatus,
  changeOrdersGroupStatusFailure
} from "../actions/ordersActions";
import { calculateOrderTotal } from "../../components/Preparing/OrderDetails/PaymentDetails/index";
import { HamburginiStoreIdToRemoveGiftsOrders } from "../../../CallCenter-App/components/OrderingBoard/constants";
import i18n from "../../../i18n/i18n";
import * as Strings from '../../../Pickup-App/i18n/strings/newOrder'

const actionType = union({ receiveNewOrder });
function* newOrderArrivedSaga(action: typeof actionType) {
  try {
    const audioObj = new Audio(require("../../assets/twin.aac").default);
    const storeId = yield select((state : IRootReducerState) => state.storeReducer.store?.id);
    // !! Temporary_Feature - Temporary_Hamburgini_Gifts_Orders
    // !! remove if condition if you want to remove the feature 
    if (storeId !== HamburginiStoreIdToRemoveGiftsOrders || action.payload.has_gifts !== 1) {
      audioObj.play();
      customNotify.info(
        i18n.t(Strings.totalInvoiceMessage, {
          ns: "newOrder",
          total_invoice: calculateOrderTotal(action.payload)
        })
      );
    }
    const isActive = yield select(
      (state: IRootReducerState) => state.lockScreenReducer.userActive
    );
    yield put(
      changeOrdersGroupStatus({
        orderIds: [action.payload.id],
        status: isActive ? OrderStatus.seen : OrderStatus.received
      })
    );
  } catch (e) {
    yield put(changeOrdersGroupStatusFailure(e));
  }
}

export function* watchNewOrderArrivedSaga() {
  yield takeLatest(ACTION_RECEIVE_NEW_ORDER, newOrderArrivedSaga);
}
