import * as React from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import * as styles from "./styles.module.sass";
import { useEffect, useState } from "react";
import { ISelectItemData } from "../../../../Shared-Components/FormElements/RenderDropDownSelect";
import { useToggleState } from "../../../../../../Utils/custom-hooks/useToggleState";

interface ISelectItemProps {
  item: ISelectItemData;
}

const SelectItem: React.FunctionComponent<ISelectItemProps> = ({ item }) => {
  return <div className={styles.item}>{item.label}</div>;
};

interface IRenderDropDownSelectProps {
  options: any[];
  onChange: (value: any) => void;
  initialValue?: any;
  className?: string;
  useFirstAsDefault?: boolean;
  disabled?: boolean;
  menuWrapperStyle?: string;
  renderSelectItem?: (item: ISelectItemData) => React.ReactChild;
  renderSelectToggle?: (item: ISelectItemData) => React.ReactChild;
}

const defaultRenderSelectToggle = (item: ISelectItemData) => item.label;
const SelectItemWrapper: React.FunctionComponent<{
  onSelect: (value: any) => void;
  value: any;
}> = props => {
  const onSelect = () => props.onSelect(props.value);
  return <DropdownItem onClick={onSelect}>{props.children}</DropdownItem>;
};

export const CustomDropDownSelect: React.FunctionComponent<
  IRenderDropDownSelectProps
> = props => {
  const {
    options,
    className,
    renderSelectItem,
    disabled,
    renderSelectToggle,
    onChange,
    initialValue,
    menuWrapperStyle
  } = props;
  const [value, selectValue] = useState(initialValue || options[0]);
  const [isOpen, toggleMenu] = useToggleState(false);
  useEffect(
    () => {
      if (initialValue) {
        selectValue(initialValue);
      }
    },
    [initialValue]
  );

  const handleChange = v => {
    selectValue(v);
    onChange(v);
  };
  const defaultRenderSelectItem = option => <SelectItem item={option} />;

  return (
    <div className={`${className || ""} ${styles.dropDownPart}`}>
      <Dropdown isOpen={isOpen} toggle={toggleMenu}>
        <DropdownToggle disabled={disabled} className={styles.switch}>
          {(renderSelectToggle || defaultRenderSelectToggle)(value)}
        </DropdownToggle>

        <DropdownMenu
          className={`${menuWrapperStyle || ""} ${styles.dropdown}`}
        >
          {options.map((option, index) => (
            <SelectItemWrapper
              key={index}
              value={option}
              onSelect={handleChange}
            >
              {(renderSelectItem || defaultRenderSelectItem)(option)}
            </SelectItemWrapper>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
