export const availableRequest = "availableRequest";
export const unAvailableRequest = "unAvailableRequest";
export const available = "available";
export const unAvailable = "unAvailable";
export const firstReason = "firstReason";
export const secondReason = "secondReason";
export const thirdReason = "thirdReason";
export const orderStatus = "orderStatus";
export const reason = "reason";
export const other = "other";
export const confirm = "confirm";
export const orderStatusTitle = "orderStatusTitle";
export const changedStatusSuccessful = "changedStatusSuccessful";
export const somethingWentWrong = "somethingWentWrong";
export const offlineFor = "offlineFor";
export const halfHour = "halfHour";
export const threeHours = "threeHours";
export const oneHour = "oneHour";
export const eightHours = "eightHours";
export const warningNoChange = "warningNoChange";

export const delivery = "delivery";
export const pickup = "pickup";
export const indefinitely = "indefinitely";
export const until_next_day = "until_next_day";

export const active = "active";
export const inactive = "inactive";
export const notAvailableFor = "notAvailableFor";
export const edit = "edit";
