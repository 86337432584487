import {
  getBranchInfo as getBranchInfoAction,
  updateBranchDeliveryMethodAction
} from "../../constants/actions";
import { payload, action } from "ts-action";
import { DeliveryMethod, IBranchOrdering } from "../../../data-model/types";

export const getBranchInfo = action(
  getBranchInfoAction.requested,
  payload<{ branch_id: string }>()
);
export const getBranchInfoSuccess = action(
  getBranchInfoAction.fulfilled,
  payload<IBranchOrdering>()
);
export const getBranchInfoFailure = action(getBranchInfoAction.rejected);

export const updateBranchInfo = action(
  updateBranchDeliveryMethodAction.requested,
  payload<{ branch_id: string; deliveryMethod: DeliveryMethod }>()
);

export const updateBranchInfoSuccess = action(
  updateBranchDeliveryMethodAction.fulfilled,
  payload<IBranchOrdering>()
);
export const updateBranchInfoFailure = action(
  updateBranchDeliveryMethodAction.rejected
);
