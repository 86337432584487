import { select, takeLatest, put, retry, call } from "redux-saga/effects";
import { customPickupNotify } from "../../util/custom-pickup-notify";
import i18n from "../../../i18n/i18n";
import { union } from "ts-action";
import { selectToken } from "../../../redux-store/selectors";
import Strings from "../../i18n/strings/branchInfo";
import {
  getBranchByIdAPI,
  updateBranchDeliveryMethod
} from "../../axios/branchInfo";
import {
  getBranchInfo,
  updateBranchInfo,
  getBranchInfoFailure,
  getBranchInfoSuccess,
  updateBranchInfoFailure,
  updateBranchInfoSuccess
} from "../actions/branchInfo";

const getActionType = union({ getBranchInfo });
function* getBranchInfoSaga(action: typeof getActionType) {
  try {
    const { branch_id } = action.payload;
    const token: string = yield select(selectToken);
    const res = yield retry(3, 1000, getBranchByIdAPI, token, branch_id);
    yield put(getBranchInfoSuccess(res.data));
  } catch (e) {
    yield put(getBranchInfoFailure());
  }
}

const updateActionType = union({ updateBranchInfo });
function* updateBranchInfoSaga(action: typeof updateActionType) {
  try {
    const { branch_id, deliveryMethod } = action.payload;
    const token: string = yield select(selectToken);
    // yield put(updateBranchInfo({ branch_id, deliveryMethod }));
    const res = yield call(
      updateBranchDeliveryMethod,
      token,
      branch_id,
      deliveryMethod
    );
    yield put(updateBranchInfoSuccess(res.data));
    customPickupNotify.success(
      i18n.t(Strings.successMessage, { ns: "branchInfo" })
    );
  } catch (e) {
    yield put(updateBranchInfoFailure());
  }
}

export function* watchBranchInfoSaga() {
  yield takeLatest(getBranchInfo.type, getBranchInfoSaga);
  yield takeLatest(updateBranchInfo.type, updateBranchInfoSaga);
}
