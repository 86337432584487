import { action, payload } from "ts-action";
import {
  ACTION_STORE_STORE,
  ACTION_SET_APP_ACTIVE_STATE,
  ACTION_SWITCH_APPS
} from "../../constants";
import { IStore } from "../../data-model/types";
import { CashierApps } from "../../components/AppSelector";

export * from "./sync-actions";
export * from "./db-actions";
export * from "./auth-actions";
export * from "./internet-actions";
export * from "./get-countries-actions";
export * from "./log-add-points";
export * from "./log-redeem-points";
export * from "./get-accounts-actions";
export * from "./switch-account-actions";

export const storeStore = action(ACTION_STORE_STORE, payload<IStore>());
export const setAppActiveState = action(
  ACTION_SET_APP_ACTIVE_STATE,
  payload<boolean>()
);
export const switchAppsAction = action(
  ACTION_SWITCH_APPS,
  payload<CashierApps>()
);
export const logCurrentAppVersion = action(
  "ACTION_LOG_CURRENT_APP_VERSION",
  payload<{ app_version: string }>()
);
