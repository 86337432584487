import { reducer, on } from "ts-action";
import { toggleModifierItem } from "../actions/menuActions";
import { toggleModifierItemAction } from "../../constants/actions";
import { withLoadingReducer } from "./withLoadingState";
import { IModifier } from "./lib";

export const modifierItemReducer = withLoadingReducer<IModifier>(
  reducer<IModifier>(
    [
      on(toggleModifierItem, state => {
        return { ...state, enabled: Number(!state.enabled) };
      })
    ],
    {
      ar_name: "",
      en_name: "",
      name: "",
      price: "",
      enabled: 0,
      id: ""
    }
  ),
  toggleModifierItemAction
);
