import { getMenuAction } from "../../constants/actions";
import { select, call, put, takeLatest } from "redux-saga/effects";
import {
  getMenu,
  getMenuFailure,
  getMenuSuccess
} from "../actions/menuActions";
import {
  selectCashierBranchId,
  selectOrderingMenuDB,
  selectToken
  // selectCashierBranchId
} from "../../../redux-store/selectors";
import { getMenuAPI, IGetMenuResponse } from "../../axios/getMenu";
import { union } from "ts-action";
import { clearOrderingMenuDB, orderingMenuDBSetter } from "../../helpers/orderingMenuDBHelpers";
import { TimeToExpire } from "../../components/Menu";
import { formatCategories } from "../reducers/menuReducer";

const actionType = union({ getMenu });

function* getMenuSaga(actions: typeof actionType) {
  try {
    const { branch_id } = actions.payload;
    const token = yield select(selectToken);
    const orderingMenuDBInstance = yield select(selectOrderingMenuDB);
    const lastHydrated = yield call(async () => {
      const data = await orderingMenuDBInstance?.schedulers.toArray();
      return data;
    });
    if (lastHydrated.length) {
      const lastHydratedValue = lastHydrated[0].hydrated_at;
      const latestBranchId = lastHydrated[0].branch_id;
      const isExpired: boolean = (Date.now() - lastHydratedValue) >= TimeToExpire;
      if (isExpired || latestBranchId !== branch_id) {
        yield call(clearOrderingMenuDB, orderingMenuDBInstance)
        const res = yield call(getMenuAPI, token, branch_id);

        yield put(getMenuSuccess(res.data))
        const { categoriesById,
          modifierGroupsById, modifierItemsById,
          itemsById } = yield call(formatCategories, res.data?.categories as IGetMenuResponse["categories"]);

        yield call(orderingMenuDBSetter, orderingMenuDBInstance, itemsById, categoriesById, modifierItemsById, modifierGroupsById, branch_id)
      } else {

        const categories = yield call(async () => {
          const value = await orderingMenuDBInstance?.categories.toArray();
          return value;
        });

        const items = yield call(async () => {
          const value = await orderingMenuDBInstance?.items.toArray();
          return value;
        });

        const groups = yield call(async () => {
          const value = await orderingMenuDBInstance?.modifiers_groups.toArray();
          return value;
        });

        const modifiers = yield call(async () => {
          const value = await orderingMenuDBInstance?.modifiers.toArray();
          return value;
        });

        const mappedMenu = {
          categories: categories.map(cat => {
            return { ...cat, items: items.filter(item => item.category_id === cat.id).map(item => ({ ...item, modifiers_groups: groups.filter(group => group.item_id === item.id).map(group => ({ ...group, modifiers: modifiers.filter(modifier => modifier.modifier_group_id === group.id) })) })) }
          })
        }
        yield put(getMenuSuccess(mappedMenu))

      }
    } else {

      const res = yield call(getMenuAPI, token, branch_id);
      yield call(clearOrderingMenuDB, orderingMenuDBInstance)
      yield put(getMenuSuccess(res.data))
      const { categoriesById,
        modifierGroupsById, modifierItemsById,
        itemsById } = yield call(formatCategories, res.data?.categories as IGetMenuResponse["categories"]);

      yield call(orderingMenuDBSetter, orderingMenuDBInstance, itemsById, categoriesById, modifierItemsById, modifierGroupsById, branch_id)


    }
  } catch (e) {
    yield put(getMenuFailure(e));
  }
}

export function* watchGetMenuSaga() {
  yield takeLatest(getMenuAction.requested, getMenuSaga);
}
