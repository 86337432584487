import React, { useEffect, useMemo, useState, useCallback } from "react";
import * as styles from "./styles.module.sass";
import { SingleCategoryItem } from "./SingleCategoryItem";
import { MainHeader } from "./MenuNavigator";
import { useDispatch } from "react-redux";
import {
  getMenu,
  MenuEntityAvailability,
  toggleModifierItem
} from "../../redux/actions/menuActions";
import { IScrollAbleCategoryProps } from "./MenuNavigator/ItemsNavigator/SingleItem";
import { ICategory } from "../../redux/reducers/lib";
import { AnchorElement, ScrollPanel } from "react-spy-scroll";
import * as Strings from "../../i18n/strings/menu";
import { useTranslation } from "../../hooks/useTranslation";
import { LoadingStatus } from "../../redux/reducers/withLoadingState";
import { useSelect } from "../../../helpers/use-select";
import {
  MarkEntitySoldOutContext,
  handleSelectItem
} from "./MarkItemSoldOutPopup";
import { ISelectItemData } from "../AvailableRequests/AvailableRequestsModal/RenderDropDownSelect";
import SelectBranch from "../../../CallCenter-App/components/SelectBranch";
import { useRecoilState } from "recoil";
import { selectedMenuBranchAtom } from "./atoms/selectedMenuBranch";
import { IBranch } from "../../../data-model/types/index";
import ConfirmModal from "./ConfirmModal";
import ConfirmSimilarModal from "./ConfirmModal/confirmSimilarModal";
import { selectOrderingMenuDB } from "../../../redux-store/selectors";

export const TimeToExpire = 3*60*1000;

interface IProps {
  whiteMode?: boolean;
}

export const Menu: React.FC<IProps> = ({ whiteMode }) => {
  const [selectedBranchId, setSelectedBranchId] = useRecoilState(
    selectedMenuBranchAtom
  );

  const dispatch = useDispatch();

  const {
    shownCategories: categories,
    categoriesById,
    itemsById,
    loadingStatus
  } = useSelect(state => state.menuReducer);

  const categoriesTitles: IScrollAbleCategoryProps[] = useMemo(
    () =>
      categories.length
        ? categories.map(({ id }): IScrollAbleCategoryProps => {
          const category = categoriesById[id] as ICategory;
          return {
            isScrolled: false,
            item: category,
            to: id
          };
        })
        : [],
    [categories]
  );

  const itemsCount = useMemo(
    () => categories.reduce((acc, next) => acc + next.items.length, 0),
    [categories]
  );

  const [handleSelectItemForMarking, setHandleSelectItem] =
    useState<handleSelectItem>(status => undefined);

  const [modifierItemsToChange, setModifierItemsToChange] = useState<
    { parentEnabled: boolean; selfEnabled: boolean; id: string }[]
  >([]);
  const [similarModifiersChangePopupOpen, setSimilarModifiersChangePopupOpen] =
    useState<boolean>(false);
  const [finalSimilarChange, setFinalSimilarChange] = useState<boolean>(false);
  const [changedItemId, setChangedItemId] = useState<string | undefined>();
  const [, setChangedItem] = useState<any>();
  const [similarChanged, setSimilarChanged] = useState<boolean>(false);
  const [markingOpen, setMarkingOpen] = useState(false);
  const [openModifierGroup, setModifierGroup] = useState(false);
  const [openModifierItem, setOpenModifierItem] = useState(false);
  const handleOpeningMarking = useCallback(() => setMarkingOpen(true), []);
  const handleClosingMarking = useCallback(() => setMarkingOpen(false), []);
  const handleOpeningModifierItem = useCallback(
    () => setOpenModifierItem(true),
    []
  );
  const handleClosingModifierItem = useCallback(
    () => setOpenModifierItem(false),
    []
  );
  const handleOpeningModifierGroup = useCallback(
    () => setModifierGroup(true),
    []
  );
  const handleClosingModifierGroup = useCallback(
    () => setModifierGroup(false),
    []
  );
  const openDisableModal = useCallback((fn: handleSelectItem) => {
    handleOpeningMarking();
    setHandleSelectItem(() => fn);
  }, []);
  const openDisableModifierGroupModal = useCallback((fn: handleSelectItem) => {
    handleOpeningModifierGroup();
    setHandleSelectItem(() => fn);
  }, []);
  const openDisableModifierItemModal = useCallback((fn: handleSelectItem) => {
    handleOpeningModifierItem();
    setHandleSelectItem(() => fn);
  }, []);
  const { t } = useTranslation("menu");
  useEffect(() => {
    if (selectedBranchId) {
      dispatch(getMenu({ branch_id: selectedBranchId }));
    }
    const reloadId = setInterval(() => {
      if (selectedBranchId) {
        dispatch(getMenu({ branch_id: selectedBranchId }));
      }
    }, TimeToExpire);
    return () => clearInterval(reloadId);
  }, [selectedBranchId]);
  // tslint:disable-next-line: no-empty
  const filterMenu = useCallback((item: ISelectItemData) => {
    setSelectedBranchId(item.value);
  }, [selectedBranchId]);

  const handleBranchesLoaded = useCallback(
    (branches: IBranch[]) => {
      if (selectedBranchId) {
        const branch = branches.find(option => option.id === selectedBranchId);
        filterMenu(
          branch
            ? { label: branch.name, value: branch.id }
            : { label: branches[0].name, value: branches[0].id }
        );
      }
    },
    [filterMenu, selectedBranchId]
  );

  const { modifierItemsById, modifierGroupsById } = useSelect(
    state => state.menuReducer
  );

  useEffect(() => {
    if (
      changedItemId &&
      finalSimilarChange &&
      modifierItemsToChange.length > 0 &&
      !similarChanged
    ) {
      setSimilarModifiersChangePopupOpen(true);
    }
  }, [finalSimilarChange, modifierItemsToChange]);

  const updateSimilarItems = () => {
    if (
      changedItemId &&
      finalSimilarChange &&
      modifierItemsToChange.length > 0 &&
      !similarChanged
    ) {
      setSimilarChanged(true);
      modifierItemsToChange.forEach((modifier, index) => {
        if (modifier.parentEnabled && modifier.id !== changedItemId) {
          dispatch(
            toggleModifierItem({
              id: modifier.id,
              status: modifierItemsById[changedItemId]
                .status as MenuEntityAvailability,
              branch_id: selectedBranchId
            })
          );
        }

        if (index === modifierItemsToChange.length - 1) {
          setChangedItem(undefined);
          setChangedItemId(undefined);
          setModifierItemsToChange([]);
          setSimilarChanged(false);
        }
      });
    }
  };

  useEffect(() => {
    if (
      changedItemId &&
      modifierItemsById[changedItemId] &&
      modifierItemsById[changedItemId].loadingStatus === LoadingStatus.success
    ) {
      setModifierItemsToChange([]);
      const targetItem = modifierItemsById[changedItemId];
      categories.forEach(category => {
        category.items.forEach((item, itemIndex) => {
          item.modifierGroups.forEach(
            ({ id, modifierItems }, modifierGroupsIndex) => {
              const group = modifierGroupsById[id];
              modifierItems.forEach((modifierItemId, modifierItemIndex) => {
                const itemToCompareWith = modifierItemsById[modifierItemId];

                if (
                  itemToCompareWith.en_name === targetItem.en_name &&
                  itemToCompareWith.ar_name === targetItem.ar_name &&
                  itemToCompareWith.price === targetItem.price &&
                  itemToCompareWith.id !== targetItem.id
                ) {
                  setModifierItemsToChange(prev => [
                    ...prev,
                    {
                      parentEnabled: Boolean(group.enabled),
                      selfEnabled: Boolean(itemToCompareWith.enabled),
                      id: modifierItemId
                    }
                  ]);
                }

                if (
                  modifierItemIndex === modifierItems.length - 1 &&
                  modifierGroupsIndex === item.modifierGroups.length - 1 &&
                  itemIndex === category.items.length - 1
                ) {
                  setFinalSimilarChange(true);
                }
              });
            }
          );
        });
      });
    }
  }, [modifierItemsById]);

  const handleModifierItemChange = (id: string) => {
    setChangedItemId(id);
    setChangedItem(modifierItemsById[id]);
  };

  return (
    <MarkEntitySoldOutContext.Provider
      value={{
        open: openDisableModal,
        openModifierGroup: openDisableModifierGroupModal,
        openModifierItem: openDisableModifierItemModal
      }}
    >
      <div className={`${whiteMode ? styles.whiteModeMenu : styles.menu}`}>
        {whiteMode && (
          <div className={styles.header}>
            <span className={styles.title}>{t(Strings.menu)}</span>
            <div className={styles.dropDownWrapper}>
              <SelectBranch
                useFirstAsDefault={true}
                onBranchesLoaded={handleBranchesLoaded}
                onChange={filterMenu}
              />
            </div>
          </div>
        )}
        <MainHeader
          number={itemsCount}
          items={categoriesTitles}
          whiteMode={whiteMode}
        />
        <ScrollPanel
          tag="div"
          style={{ overflow: "auto", width: "100%", flex: 1 }}
        >
          <>
            {categories.map(({ id, items }) => {
              const category = categoriesById[id];
              return (
                <AnchorElement key={id} id={id}>
                  <SingleCategoryItem
                    whiteMode={whiteMode}
                    itemNumber={items.length}
                    id={id}
                    active={Boolean(category.enabled)}
                    menuItems={items.map(item => ({
                      item: itemsById[item.id],
                      modifierGroups: item.modifierGroups
                    }))}
                    handleModifierItemChange={handleModifierItemChange}
                  />
                </AnchorElement>
              );
            })}
          </>
          <>
            {categories.length === 0 &&
              loadingStatus === LoadingStatus.success && (
                <div className={styles.emptyResults}>
                  {t(Strings.noResultsFound)}
                </div>
              )}
          </>
        </ScrollPanel>
      </div>
      {markingOpen && (
        <ConfirmModal
          closeHandler={handleClosingMarking}
          onSelectOption={handleSelectItemForMarking}
          open={markingOpen}
          soldOutAllDayDescription={t(Strings.categoryIsSoldOutDescription)}
          soldOutAllDayTitle={t(Strings.categoryIsSoldOut)}
          title={t(Strings.selectTheCategoryState)}
          soldOutIndefinitelyDescription={t(
            Strings.categoryIsRunOutIndefinitelyDescription
          )}
          soldOutIndefinitelyTitle={t(Strings.categoryIsRunOutIndefinitely)}
        />
      )}
      {openModifierGroup && (
        <ConfirmModal
          soldOutAllDayDescription={t(
            Strings.modifierGroupIsSoldOutDescription
          )}
          soldOutAllDayTitle={t(Strings.modifierGroupIsSoldOut)}
          soldOutIndefinitelyDescription={t(
            Strings.modifierGroupIsRunOutIndefinitelyDescription
          )}
          soldOutIndefinitelyTitle={t(
            Strings.modifierGroupIsRunOutIndefinitely
          )}
          closeHandler={handleClosingModifierGroup}
          onSelectOption={handleSelectItemForMarking}
          open={openModifierGroup}
          title={t(Strings.selectTheModifierGroupState)}
        />
      )}
      {openModifierItem && (
        <ConfirmModal
          soldOutAllDayDescription={t(Strings.modifierItemIsSoldOutDescription)}
          soldOutAllDayTitle={t(Strings.modifierItemIsSoldOut)}
          soldOutIndefinitelyDescription={t(
            Strings.modifierItemIsRunOutIndefinitelyDescription
          )}
          soldOutIndefinitelyTitle={t(Strings.modifierItemIsRunOutIndefinitely)}
          closeHandler={handleClosingModifierItem}
          onSelectOption={handleSelectItemForMarking}
          open={openModifierItem}
          title={t(Strings.selectTheModifierItemState)}
        />
      )}

      <ConfirmSimilarModal
        title={t(Strings.changeSimilarTitle)}
        subTitle={t(Strings.changeSimilarSubTitle, {
          similarCount: modifierItemsToChange.length
        })}
        open={similarModifiersChangePopupOpen}
        handleSubmit={updateSimilarItems}
        closeHandler={() => setSimilarModifiersChangePopupOpen(false)}
      />
    </MarkEntitySoldOutContext.Provider>
  );
};
