import axios from "./axios";
import { itemAvailability } from "./requests";
import { MenuEntityAvailability } from "../redux/actions/menuActions";

export const ChangeableAvailability = {
  [MenuEntityAvailability.disabled]: MenuEntityAvailability.disabled,
  [MenuEntityAvailability.enabled]: "available",
  [MenuEntityAvailability.sold_out_all_day]:
    MenuEntityAvailability.sold_out_all_day,
  [MenuEntityAvailability.sold_out_indefinitely]:
    MenuEntityAvailability.sold_out_indefinitely,
  [MenuEntityAvailability.hidden]: MenuEntityAvailability.hidden
};

export const changeItemAvailability = (
  token: string,
  branch_id: string,
  id: string,
  availability: MenuEntityAvailability
) => {
  return axios.put(
    itemAvailability + "/" + id,
    { availability: ChangeableAvailability[availability], branch_id },
    {
      headers: {
        token
      }
    }
  );
};
