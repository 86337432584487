import { getOrdersAction } from "../../constants/actions";
import { select, put, takeLatest, call } from "redux-saga/effects";
import * as actions from "../actions/ordersActions";
import { selectToken } from "../../../redux-store/selectors";
import { getOrders } from "../../axios/getOrders";
import { union } from "ts-action";
import { IOrder } from "../reducers/lib";
import { OrderStatus } from "../../constants/types";
import { IRootReducerState } from "../../../redux-store/rootReducer";
const actionType = union({ getOrders: actions.getOrders });

function* getOrdersSaga(action: typeof actionType) {
  try {
    const token = yield select(selectToken);
    const res = yield call(getOrders, token, action.payload);
    const orders: IOrder[] = res.data.orders || [];
    yield put(actions.getOrdersSuccess(orders));
    const isActive = yield select(
      (state: IRootReducerState) => state.lockScreenReducer.userActive
    );
    const unSeenOrders = orders
      .filter(a => a.status === OrderStatus.sent)
      .map(order => order.id);
    if (unSeenOrders.length) {
      yield put(
        actions.changeOrdersGroupStatus({
          orderIds: unSeenOrders,
          status: isActive ? OrderStatus.seen : OrderStatus.received
        })
      );
    }
  } catch (e) {
    yield put(actions.getOrdersFailure());
  }
}

export function* watchGetOrdersSaga() {
  yield takeLatest(getOrdersAction.requested, getOrdersSaga);
}
