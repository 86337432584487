export default {
  orderSettings: "إعدادات الطلب",
  branchListing: "قائمة الفروع",
  placeholderForOrderSettings: "ابحث عن اسم الفرع…",
  avgOrderTime: "متوسط وقت الطلب",
  orderingNotAvailable: "الطلب غير متوفر",
  orderingAvailable: "الطلب متاح",
  pickup: "الاستلام عند الوصول",
  delivery: "التوصيل",
  edit: "تعديل إعدادات طلب “{{name}}”",
  orderAvailability: "توفر الطلب",
  avgTime: "متوسط الوقت",
  deliveryTime: "وقت التوصيل",
  preparationTime: "وقت التحضير",
  minutes: "دقائق",
  to: "الي",
  from: "من",
  time: "وقت",
  save: "حفظ",
  inactive: "غير نشط",
  open: "مفتوح",
  closed: "مغلق",
  disconnected: "غير متصل"
};
