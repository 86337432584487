import { ToggleOrderingStatusType, IService } from "../../types";
import { OrderStatusChange } from "../constants/types";

export const getOrderDetails = (id: string) =>
  `/api/cashier/call-center/orders/${id}`;
export const branchOrderingStatus = "/api/cashier/ordering-statuses";
export const storeOrderingStatus = "/api/cashier/store/pickup/status";
export const reEnableOrderingStatus = `/api/cashier/re-enable-ordering`;
export const pauseOrderingStatus = "/api/cashier/pause-ordering";
export const disableReasons = "/api/cashier/pickup/disable-reasons";
export const getOrders = "/api/cashier/call-center/today-orders";
export const modifierAvailability =
  "/api/cashier/call-center/modifier-availability";
export const modifierGroupAvailability =
  "/api/cashier/call-center/modifiers-group-availability";
export const itemAvailability = "/api/cashier/call-center/item-availability";
export const CategoryAvailability =
  "/api/cashier/call-center/category-availability";
export const getMenu = "/api/cashier/call-center/list-menu-items";
export const changeOrderStatus = (statusChange: OrderStatusChange) =>
  `api/cashier/call-center/orders/status/${statusChange}`;
export const cancelOrder = "/api/cashier/orders/cancel";
export const branchAvgPrepTime = (id: string) =>
  `/api/cashier/pickup/average-prep-time/${id}`;
export const orderAvgPrepTime = (id: string) =>
  `/api/cashier/pickup/order-average-prep-time/${id}`;

export const branchDeliveryAvgPrepTime = (id: string) => ` ${id}`;
export const dispatchOrderForFleet = (orderId: string) =>
  `/api/cashier/delivery-orders/dispatch-to-dms/${orderId}`;

export const pastOrders = "/api/cashier/call-center/previous-orders";
export const store = "/api/cashier/store";
export const assistanceRequest = "/api/cashier/call-center/assistance-request";
export const getStatusReason = "/api/cashier/order-status-reasons";
export const rejectOrder = "/api/cashier/orders/reject";
export const cancellation = "/api/cashier/orders/cancel";
export const canChooseReason = (orderId: string) =>
  `/api/cashier/orders/${orderId}/can-choose-reason`;
export const getDeliveryReasonAPI =
  "/api/cashier/order-status-reasons/delivery";

export const markAsOutForDelivery =
  "/api/cashier/delivery-orders/mark-out-for-delivery";
export const markAsDelivered = "/api/cashier/delivery-orders/mark-delivered";

export const enableOrdering = (
  type: ToggleOrderingStatusType,
  service: IService
) => `/api/cashier/call-center/${type}-${service}`;

export const getCallCenterAgentBranches = "/api/cashier/call-center/branches";
export const getBranchInfo = (branch_id: string) =>
  `api/cashier/call-center/branch/${branch_id}`;

export const updateBranchDeliveryMethod = (branch_id: string) =>
  `api/cashier/call-center/branch/delivery-method/${branch_id}`;
