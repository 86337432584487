import {
  getOrderingStatusAction,
  changeOrderingStatusAction,
  getStoreOrderingStatusAction,
  reEnableOrderingStatusAction,
  toggleOrderingStatusAction
} from "../../constants/actions";
import { OrderingStatus } from "../../components/AvailableRequests/AvailableRequestsModal";
import { action, payload } from "ts-action";
import { IReason } from "../../hooks/useGetDisableReasons";
import { errorMessage } from "./menuActions";
import { optAction } from "./lib";
import { ToggleOrderingStatusType, IService } from "../../../types";
import { IGetBranchOrderingStatusRes } from "../../axios/getBranchOrderingStatus";

export interface IChangeOrderingStatusParams {
  status: OrderingStatus;
  reason?: IReason;
  branch_id: string;
  interval?: string;
}

export const enum pauseUntil {
  indefinitely = "indefinitely",
  till_next_day = "till_next_day",
  interval = "interval"
}
export interface IToggleOrderingStatusParams {
  type: ToggleOrderingStatusType;
  service: IService;
  branch_id: string;
  pause_interval?: number;
  reason_id?: string;
  pause_until?: pauseUntil;
}
export interface IToggleOrderingStatusReturn {
  status: OrderingStatus;
  pause_until: pauseUntil;
  pause_interval?: number;
}

export const getOrderingStatus = action(getOrderingStatusAction.requested);

export const getOrderingStatusSuccess = action(
  getOrderingStatusAction.fulfilled,
  payload<IGetBranchOrderingStatusRes>()
);
export const getOrderingStatusFailure = action(
  getOrderingStatusAction.rejected,
  payload<errorMessage>()
);

export const getStoreOrderingStatus = action(
  getStoreOrderingStatusAction.requested
);

export const getStoreOrderingStatusSuccess = action(
  getStoreOrderingStatusAction.fulfilled,
  payload<OrderingStatus>()
);
export const getStoreOrderingStatusFailure = action(
  getStoreOrderingStatusAction.rejected,
  payload<errorMessage>()
);

export const changeOrderingStatus = optAction(
  changeOrderingStatusAction.requested,
  payload<IChangeOrderingStatusParams>()
);
export const changeOrderingStatusSuccess = action(
  changeOrderingStatusAction.fulfilled,
  payload<{ delivery_status: OrderingStatus; pickup_status: OrderingStatus }>()
);
export const changeOrderingStatusFailure = action(
  changeOrderingStatusAction.rejected,
  payload<errorMessage>()
);

export const reEnableOrderingStatus = action(
  reEnableOrderingStatusAction.requested,
  payload<IChangeOrderingStatusParams>()
);
export const reEnableOrderingStatusFailure = action(
  reEnableOrderingStatusAction.rejected,
  payload<errorMessage>()
);
export const reEnableOrderingStatusSuccess = action(
  reEnableOrderingStatusAction.fulfilled,
  payload<IChangeOrderingStatusParams>()
);

export const toggleOrderingStatus = action(
  toggleOrderingStatusAction.requested,
  payload<IToggleOrderingStatusParams>()
);
export const toggleOrderingStatusFailure = action(
  toggleOrderingStatusAction.rejected,
  payload<errorMessage>()
);
export const toggleOrderingStatusSuccess = action(
  toggleOrderingStatusAction.fulfilled,
  payload<IToggleOrderingStatusParams>()
);
