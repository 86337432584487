import { PersistConfig, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import dbReducer from "./reducers/dbReducer";
import appReducer from "./reducers/appReducer";
import { branchesReducer } from "../CallCenter-App/react-redux/reducers";
import syncReducer from "./reducers/syncReducer";
import { ordersReducer } from "../Pickup-App/redux/reducers/getOrdersReducer";
import { default as authReducer } from "./reducers/authReducer";
import { searchCustomersReducer } from "../components/SearchCustomers/reducer";
import networkStateReducer from "./reducers/networkStateReducer";
import { FormState } from "redux-form";
import form from "redux-form/es/reducer";
import { redeemGiftReducer } from "./reducers/redeemGiftsReducer";
import logAddPointsReducer from "./reducers/logAddPointsReducer";
import logRedeemPointsReducer from "./reducers/logRedeemPointsReducer";
import { loadingBarReducer } from "react-redux-loading-bar";
import { managePointsReducer } from "../web-components/ManagePoints/reducer";
import getAccountsReducer from "./reducers/getAccounts";
import switchAccountReducer from "./reducers/switchAccounts";
import { selectBranchReducer } from "../web-components/SelectCallCenterBranch/reducer";
import { orderingStatus } from "../Pickup-App/redux/reducers/orderingStatus";
import { menuReducer } from "../Pickup-App/redux/reducers/menuReducer";
import { lockScreenReducer } from "../Pickup-App/redux/reducers/lockScreenReducer";
import { branchAvgPrepTimeReducer } from "../Pickup-App/redux/reducers/branchAvgPrepTimeReducer";
import { storeReducer } from "../Pickup-App/redux/reducers/storeReducer";
import { contactSupportReducer } from "../Pickup-App/redux/reducers/contactSupportReducer";
import { getStatusReasonReducer } from "../Pickup-App/redux/reducers/getStatusReasonReducer";
import { canChooseReasonReducer } from "../Pickup-App/redux/reducers/canChooseReasonReduce";
import { getDeliveryReasonsReducer } from "../Pickup-App/redux/reducers/getDeliveryReasonsReducer";
import { branchInfoReducer } from "../Pickup-App/redux/reducers/branchInfo";
import { revokeOrderReducer } from '../Pickup-App/redux/reducers/revokeOrderReducer'
import { branchDeliveryAvgPrepTimeReducer } from "../Pickup-App/redux/reducers/branchDeliveryAvgPrepTimeReducer";
import orderingMenuDBReducer from "../Pickup-App/redux/reducers/orderingMenuDBReducer";

export interface ICashierFormState {
  login: FormState;
  AddPointsPhoneNumber: FormState;
  switchAccount: FormState;
  RequestsForm: FormState;
}

const persistSync: PersistConfig = {
  key: "syncReducer",
  storage,
  blacklist: ["syncState"]
};

const persistAuth: PersistConfig = {
  key: "authReducer",
  storage,
  blacklist: ["loadingStatus"]
};

const rootReducer = combineReducers({
  appReducer,
  dbReducer,
  getAccountsReducer,
  logAddPointsReducer,
  switchAccountReducer,
  logRedeemPointsReducer,
  redeemGiftReducer,
  authReducer: persistReducer(persistAuth, authReducer),
  searchCustomersReducer,
  syncReducer: persistReducer(persistSync, syncReducer),
  networkStateReducer,
  loadingBar: loadingBarReducer,
  form,
  managePointsReducer,
  selectBranchReducer,
  branchesReducer,
  orderingStatus,
  menuReducer,
  ordersReducer,
  revokeOrderReducer,
  lockScreenReducer,
  branchAvgPrepTimeReducer,
  storeReducer,
  contactSupportReducer,
  getStatusReasonReducer,
  canChooseReasonReducer,
  getDeliveryReasonsReducer,
  branchDeliveryAvgPrepTimeReducer,
  branchInfoReducer,
  orderingMenuDBReducer
});
type rootReducer = typeof rootReducer;

type returnTypeInferrer<T> = T extends (state, action) => infer U ? U : never;
type rootState = returnTypeInferrer<rootReducer>;
export interface IRootReducerState extends rootState {
  form: ICashierFormState;
}

const persistorOptions: PersistConfig = {
  key: "root",
  storage,
  whitelist: []
};

const persistedReducer = persistReducer(persistorOptions, rootReducer);

export default persistedReducer;
