import React from "react";
import * as styles from "./styles.module.sass";
import { useRtlClass } from "../../../lib";

interface IProps {
  title: string;
  description?: string;
  checked: boolean;
  id: string;
  onChange: (e: any) => void;
  iconClass: string;
  icon: string;
  radioValue: string;
}
const RadioButtonField: React.SFC<IProps> = ({
  description,
  title,
  onChange,
  id,
  icon,
  iconClass,
  radioValue,
  checked
}) => {
  return (
    <label className={`${styles.wrapper} ${useRtlClass(styles)}`}>
      <div className={styles.inputWrapper}>
        <div className={iconClass}>
          <img src={icon} />
        </div>
        <div className={styles.labelWrapper}>
          <span className={styles.title}>{title}</span>
          <span className={styles.description}>{description}</span>
        </div>
      </div>
      <input
        type={"radio"}
        checked={checked}
        id={id}
        value={radioValue}
        onChange={onChange}
        className={styles.input}
      />
      <span>{checked && <span className={styles.check} />}</span>
    </label>
  );
};

export default RadioButtonField;
