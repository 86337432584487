import { action, payload } from "ts-action";
import { getBranchesAction } from "../../../constants";
import { IBranch } from "../../../data-model/types";
export const getBranches = action(getBranchesAction.requested);
export const getBranchesSuccess = action(
  getBranchesAction.fulfilled,
  payload<IBranch[]>()
);
export const getBranchesFailure = action(
  getBranchesAction.rejected,
  payload<Error>()
);
