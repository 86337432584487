import ManagePoints from "./ManagePoints";
import RedeemGifts from "./RedeemGifts";
import {
  CURRENT_LANG,
  MANAGE_POINTS,
  pointsLog,
  REDEEM_GIFTS,
  redeemLog,
  AR,
  EN
} from "../strings/App";
import Login from "./Login";
import Sync from "./Sync";
import newOrder from "../../Pickup-App/i18n/en/newOrder";
import navigation from "../../Pickup-App/i18n/en/navigation";
import logs from "./logs";
import contactshopx from "./contactshopx";
import orderingStatus from "../../Pickup-App/i18n/en/orderingStatus";
import switchAccount from "./switchAccount";
import logout from "./logout";
import axios from "./axios";
import navbar from "./navbar";
import selectBranch from "./selectBranch";
import VerifyPasswordModal from "./VerifyPasswordModal";
import header from "../../Pickup-App/i18n/en/header";
import statusNavigator from "../../Pickup-App/i18n/en/statusNavigator";
import pastOrdersStatues from "../../Pickup-App/i18n/en/pastOrdersStatues";
import SearchForProduct from "../../Pickup-App/i18n/en/SearchForProduct";
import menuItem from "../../Pickup-App/i18n/en/menuItem";
import branchInfo from "../../Pickup-App/i18n/en/branchInfo";
import StoreStatus from "../../Pickup-App/i18n/en/StoreStatus";
import callCenterNavigator from "../../CallCenter-App/i18n/en/callCenterNavigator";
import switchLanguage from "../../CallCenter-App/i18n/en/switchLanguage";
import * as menu from "../../Pickup-App/i18n/en/menu";
import lockscreen from "../../Pickup-App/i18n/en/lockscreen";
import callCenterSettings from "../../CallCenter-App/i18n/en/callCenterSettings";
import callCenterHeader from "../../CallCenter-App/i18n/en/callCenterHeader";
import orderingBoard from "../../CallCenter-App/i18n/en/orderingBoard";
export const en = {
  translations: {
    [MANAGE_POINTS]: "Manage Points",
    [REDEEM_GIFTS]: "Redeem Gifts",
    [CURRENT_LANG]: "تغيير للغة العربية",
    [AR]: "اللغة العربية",
    [EN]: "English",
    [pointsLog]: "Points Log",
    [redeemLog]: "Gifts Log"
  },
  [MANAGE_POINTS]: ManagePoints,
  [REDEEM_GIFTS]: RedeemGifts,
  Login,
  Sync,
  axios,
  logs,
  contactshopx,
  statusNavigator,
  header,
  switchAccount,
  newOrder,
  logout,
  navbar,
  selectBranch,
  navigation,
  VerifyPasswordModal,
  orderingStatus,
  menu,
  pastOrdersStatues,
  SearchForProduct,
  menuItem,
  branchInfo,
  StoreStatus,
  lockscreen,
  callCenterNavigator,
  switchLanguage,
  callCenterSettings,
  orderingBoard,
  callCenterHeader
};
