import React, { useState, useCallback, useMemo } from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { Modal } from "@material-ui/core";
import { useTranslation } from "../../../hooks/useTranslation";
import { useRtlClass } from "../../../../lib";
import * as Strings from "../../../i18n/strings/menu";
import { MenuEntityAvailability } from "../../../redux/actions/menuActions";
import RadioButtonField from "../../../Shared-Components/RadioButtonField";
import { GenericButtonWithoutIcon } from "../../../Shared-Components/GenericButtonWithoutIcon";
interface IProps {
  open: boolean;
  closeHandler: () => void;
  onSelectOption: (selectedStatus: MenuEntityAvailability) => void;
  soldOutAllDayTitle?: string;
  soldOutAllDayDescription?: string;
  soldOutIndefinitelyTitle?: string;
  soldOutIndefinitelyDescription?: string;
  title: string;
}

const ConfirmModal: React.FC<IProps> = props => {
  const {
    open,
    closeHandler,
    onSelectOption,
    soldOutAllDayDescription,
    soldOutAllDayTitle,
    soldOutIndefinitelyDescription,
    title,
    soldOutIndefinitelyTitle
  } = props;
  const { t } = useTranslation("menu");
  const [state, setState] = useState(MenuEntityAvailability.sold_out_all_day);

  const handleSelectState = useCallback(
    e => {
      setState(e.target.value);
    },
    [state]
  );

  const handleSubmit = useCallback(
    () => {
      onSelectOption(state);
      closeHandler();
    },
    [onSelectOption, state]
  );

  const options = useMemo(() => {
    return [
      {
        icon: require("../../../../CallCenter-App/assets/24.svg"),
        iconClass: styles.clockWrapper,
        title: soldOutAllDayTitle,
        description: soldOutAllDayDescription,
        radioValue: MenuEntityAvailability.sold_out_all_day
      },
      {
        icon: require("../../../../CallCenter-App/assets/clockMenu.svg"),
        iconClass: styles.iconWrapper,
        title: soldOutIndefinitelyTitle,
        radioValue: MenuEntityAvailability.sold_out_indefinitely,
        description: soldOutIndefinitelyDescription
      }
    ];
  }, []);

  const disabledItemNextStates = useMemo(() => [options[0], options[1]], []);

  const availableChoices = useMemo(
    () => {
      const map = {
        [MenuEntityAvailability.sold_out_all_day]: disabledItemNextStates,
        [MenuEntityAvailability.sold_out_indefinitely]: disabledItemNextStates
      };
      return map[state];
    },
    [state]
  );
  return (
    <Modal open={open} onClose={closeHandler} className={styles.modal}>
      <div className={`${styles.wrapper} ${useRtlClass(styles)}`}>
        <div className={styles.closeWrapper}>
          <span className={styles.title}>{title}</span>
          <img
            className={styles.icon}
            onClick={closeHandler}
            src={require("../../../../assets/close.svg")}
          />
        </div>
        {availableChoices.map(radio => (
          <RadioButtonField
            checked={state === radio.radioValue}
            key={`radio-${radio.radioValue}`}
            id={`radio-${radio.radioValue}`}
            {...radio}
            onChange={handleSelectState}
          />
        ))}

        <div className={styles.btnWrapper}>
          <GenericButtonWithoutIcon
            label={t(Strings.saveChanges)}
            onClick={handleSubmit}
            className={styles.save}
          />
        </div>
      </div>
    </Modal>
  );
};

export default hot(module)(ConfirmModal);
