import { takeLatest, select, call } from "redux-saga/effects";
import { changeBranchAvgPrepTimeAction } from "../../constants/actions";
import { handleOptimism } from "./handleOptimism";
import {
  changeBranchAvgPrepTime,
  changeBranchAvgPrepTimeSuccess,
  changeBranchAvgPrepTimeFailure
} from "../actions/branchAvgPrepTimeActions";
import {
  selectToken
  // selectCashierBranchId
} from "../../../redux-store/selectors";
import { branchAveragePrepTimeApi } from "../../axios/branchAveragePrepTime";
import { union } from "ts-action";

const actionType = union({ changeBranchAvgPrepTime });

function* changeBranchAvgPrepTimeSaga(action: typeof actionType) {
  const token = yield select(selectToken);
  // const branchId = yield select(selectCashierBranchId);
  const res = yield call(
    branchAveragePrepTimeApi.put,
    token,
    action.payload.branch_id,
    action.payload
  );
  return res.data;
}

export function* watchChangeBranchAvgPrepTime() {
  yield takeLatest(
    changeBranchAvgPrepTimeAction.requested,
    handleOptimism(changeBranchAvgPrepTimeSaga, {
      success: changeBranchAvgPrepTimeSuccess,
      failure: changeBranchAvgPrepTimeFailure
    })
  );
}
