import axios from "./axios";
import * as Requests from "./requests";

export const getCalCenterAgentBranchesApi = token => {
  return axios.get(Requests.getCallCenterAgentBranches, {
    headers: {
      token
    }
  });
};
