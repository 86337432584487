export default {
  newOrder: "New",
  preparing: "Preparing",
  ready: "Ready",
  newest: "Newest",
  oldest: "Oldest",
  shopixTitle:
    " Delivered by koinz (a courier will pick up and deliver the order to the customer)",
  id: "ID",
  deliveredTo: "Delivered to",
  branch: "Branch",
  outForDelivery: "Out for delivery",
  readyForPickup: "Ready for pickup",
  paid: "Paid",
  payment: "Payment:",
  paymentOnArrival: "On arrival",
  delivered: "Delivered",
  pickedUp: "Picked up",
  late: "late",
  min: "min",
  mins: "minutes",
  ago: "ago",
  all: "All",
  title: "Branch:",
  warningMessage: "Order {{order_code}} is revoked due to inactivity",
  inReview: "In review"
};
