import * as actions from '../../constants/actions';
import { Dexie } from 'dexie';
import { OrderingMenuDB } from '../../db/orderingMenuDb';


export interface IOpenOrderingMenuDBSuccessAction {
    type: string;
    payload: OrderingMenuDB;
  }
export const openOrderingMenuDB = () => ({
    type: actions.openOrderingMenuDBAction.requested,
});

export const openOrderingMenuDBSuccess = (orderingMenuDBInstance: Dexie) => ({
    type: actions.openOrderingMenuDBAction.fulfilled,
    payload: orderingMenuDBInstance,
});

export const openOrderingMenuDBFailure = (error: Error) => ({
    type: actions.openOrderingMenuDBAction.rejected,
    payload: error,
});

