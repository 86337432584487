import { reducer, on } from "ts-action";
import {
  getBranchInfoSuccess,
  updateBranchInfoSuccess,
  updateBranchInfo,
  updateBranchInfoFailure
} from "../actions/branchInfo";
import { getBranchInfo as getBranchInfoAction } from "../../constants/actions";
import { withLoadingReducer } from "./withLoadingState";
import {
  DeliveryManagementStatus,
  DeliveryMethod,
  IBranchOrdering
} from "../../../data-model/types";

export const branchInfoReducer = withLoadingReducer<IBranchOrdering>(
  reducer<IBranchOrdering>(
    [
      on(getBranchInfoSuccess, (state, { payload }) => payload),
      on(updateBranchInfo, (state, { payload }) => ({
        ...state,
        delivery_method: payload.deliveryMethod
      })),
      on(updateBranchInfoSuccess, (state, { payload }) => payload),
      on(updateBranchInfoFailure, state => ({
        ...state,
        delivery_method:
          state.delivery_method === DeliveryMethod.in_house_delivery
            ? DeliveryMethod.third_party_delivery
            : DeliveryMethod.in_house_delivery
      }))
    ],
    {
      created_at: 0,
      updadted_at: 0,
      updated_at: 0,
      delivery_method: DeliveryMethod.in_house_delivery,
      delivery_management_status: DeliveryManagementStatus.disabled,
      delivery_status: "enabled",
      id: "",
      lat: 0,
      lng: 0,
      pickup_prep_max: 0,
      pickup_prep_min: 0,
      pickup_status: "enabled"
    }
  ),
  getBranchInfoAction
);
