import axios from "./axios";
import * as request from "./requests";
import { IService, ToggleOrderingStatusType } from "../../types";
import { pauseUntil } from "../redux/actions/orderingStatusActions";

export const toggleOrderingStatusAPI = (
  token: string,
  branch_id: string,
  type: ToggleOrderingStatusType,
  service: IService,
  pause_until?: pauseUntil,
  reason_id?: string,
  pause_interval?: number
) => {
  return axios.put(
    request.enableOrdering(type, service),
    {
      branch_id,
      pause_until,
      reason_id,
      pause_interval
    },
    {
      headers: {
        token
      }
    }
  );
};
