import { DeliveryMethod } from "src/data-model/types";
import axios from "./axios";
import * as requests from "./requests";
export const getBranchByIdAPI = (token: string, branch_id: string) => {
  return axios.get(requests.getBranchInfo(branch_id), {
    headers: {
      token
    }
  });
};

export const updateBranchDeliveryMethod = (
  token: string,
  branch_id: string,
  delivery_method: DeliveryMethod
) => {
  return axios.put(
    requests.updateBranchDeliveryMethod(branch_id),
    { delivery_method },
    {
      headers: {
        token
      }
    }
  );
};
