import * as constants from "../constants/socket-events";
import * as actions from "../redux/actions/socket-actions";
import { SocketEvent } from "./lib";

export default [
  new SocketEvent(
    constants.SOCKET_EVENT_RECEIVE_ORDER_STATUS_CHANGE,
    actions.receiveOrderStatusChange
  ),
  new SocketEvent(
    constants.SOCKET_EVENT_RECEIVE_BRANCH_AVERAGE_PREP_TIME_CHANGE,
    actions.receiveBranchAveragePrepTimeChange
  ),
  new SocketEvent(
    constants.SOCKET_EVENT_RECEIVE_CUSTOMER_ARRIVED,
    actions.receiveCustomerArrived
  ),
  new SocketEvent(
    constants.SOCKET_EVENT_RECEIVE_DELIVERY_STATUS_CHANGE,
    actions.receiveDeliveryStatusChange
  ),
  new SocketEvent(
    constants.SOCKET_EVENT_RECEIVE_PICKUP_STATUS_CHANGE,
    actions.receivePickupStatusChange
  ),
  new SocketEvent(
    constants.SOCKET_EVENT_ORDER_REVIEWING_STATUS,
    actions.orderReviewingStatusBeforeAcceptance
  ),
  new SocketEvent(
    constants.SOCKET_EVENT_RECEIVE_NEW_ORDER,
    actions.receiveNewOrder
  )
];
