import React, { useCallback } from "react";
import * as styles from "./styles.module.sass";
import { AnchorLink } from "react-spy-scroll";
import { IBasicTranslatedObject, useTranslatedName } from "../../../util";
export interface IScrollAbleCategoryProps {
  item: IBasicTranslatedObject;
  isScrolled: boolean;
  to: string;
  whiteMode?: boolean;
  onClick?: () => void;
}

export const ScrollAbleCategoryTitle: React.FC<
  IScrollAbleCategoryProps
> = props => {
  const handleOnClickEvent = useCallback(() => {
    const element = document.getElementById(props.to) as HTMLAnchorElement;
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        left: 0,
        behavior: "smooth"
      });
    }
  }, [])
  return (
    <AnchorLink
      activeClass={`${props.whiteMode ? styles.whiteActive : styles.active}`}
      className={`${props.whiteMode ? styles.whiteItem : styles.item}`}
      href={props.to}
      onClick={handleOnClickEvent}
    >
      {useTranslatedName(props.item)}
    </AnchorLink>
  );
};
