import { action, payload } from "ts-action";
import * as actions from "../../constants/actions";
import { SocketConnectionStatus, OrderStatus } from "../../constants/types";
import { IOrder } from "../reducers/lib";
import { OrderingStatus } from "../../components/AvailableRequests/AvailableRequestsModal";
import { IBranchAvgPrepTime } from "../../axios/branchAveragePrepTime";
import { IDeliveryAvgPrepTime } from "../../axios/branchDeliveryAveragePrepTime";
export interface IOrderReviewingStatusBeforeAcceptanceRes {
  cashier_code: number;
  cashier_id: string;
  order_id: string;
  status: "reviewing" | "dismissed";
}
export const authenticateSocket = action(actions.ACTION_AUTHENTICATE_SOCKET);

export const changeConnectionStatus = action(
  actions.ACTION_CHANGE_SOCKET_STATUS,
  payload<{ status: SocketConnectionStatus }>()
);

export const receiveNewOrder = action(
  actions.ACTION_RECEIVE_NEW_ORDER,
  payload<IOrder>()
);
export const clearPrevState = action(actions.ACTION_CLEAR_PREV_STATE);
export const revokeOrder = action(
  actions.revokeOrderAction.requested,
  payload<any>()
);
export const revokeOrderSuccess = action(
  actions.revokeOrderAction.fulfilled,
  payload<{ id: string }>()
);
export const revokeOrderFailure = action(
  actions.revokeOrderAction.rejected,
  payload<Error>()
);
export const receiveOrderStatusChange = action(
  actions.ACTION_RECEIVE_ORDER_STATUS_CHANGE,
  payload<{
    status: OrderStatus;
    id: string;
    dispatched_to_third_party_delivery?: number;
    changer_id: string;
    cashier_id?: string
  }>()
);
export const receivePickupStatusChange = action(
  actions.ACTION_RECEIVE_PICKUP_STATUS_CHANGE,
  payload<{ status: OrderingStatus; id: string }>()
);
export const receiveDeliveryStatusChange = action(
  actions.ACTION_RECEIVE_DELIVERY_STATUS_CHANGE,
  payload<{ status: OrderingStatus; id: string }>()
);
export const receiveBranchAveragePrepTimeChange = action(
  actions.ACTION_RECEIVE_BRANCH_AVERAGE_PREP_TIME_CHANGE,
  payload<IBranchAvgPrepTime>()
);
export const receiveDeliveryAveragePrepTimeChange = action(
  actions.ACTION_RECEIVE_DELIVERY_AVERAGE_PREP_TIME_CHANGE,
  payload<IDeliveryAvgPrepTime>()
);
export const receiveCustomerArrived = action(
  actions.ACTION_RECEIVE_CUSTOMER_ARRIVED,
  payload<{ order_id: string }>()
);

export const receiveStoreOrderingStatusChange = action(
  actions.ACTION_RECEIVE_STORE_ORDERING_STATUS_CHANGE,
  payload<{ pickup_status: OrderingStatus }>()
);

export const orderReviewingStatusBeforeAcceptance = action(
  actions.ORDER_REVIEWING_STATUS_BEFORE_ACCEPTANCE,
  payload<IOrderReviewingStatusBeforeAcceptanceRes>()
);
