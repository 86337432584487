export const noResultsFound = "noResultsFound";
export const itemAvailable = "itemAvailable";
export const itemIsSoldOut = "itemIsSoldOut";
export const itemIsSoldOutDescription = "itemIsSoldOutDescription";
export const itemIsRunOutIndefinitely = "itemIsRunOutIndefinitely";
export const itemIsRunOutIndefinitelyDescription =
  "itemIsRunOutIndefinitelyDescription";
export const hideItemFromTheBranch = "hideItemFromTheBranch";
export const hideItemFromTheBranchDescription =
  "hideItemFromTheBranchDescription";
export const itemSettings = "itemSettings";
export const saveChanges = "saveChanges";
export const cancelSimilarChanges = "cancelSimilarChanges";
export const confirmSimilarChanges = "confirmSimilarChanges";
export const menu = "menu";
export const selectTheItemState = "selectTheItemState";
export const selectTheCategoryState = "selectTheCategoryState";
export const selectTheModifierGroupState = "selectTheModifierGroupState";
export const selectTheModifierItemState = "selectTheModifierItemState";
export const changeSimilarTitle = "changeSimilarTitle";
export const changeSimilarSubTitle = "changeSimilarSubTitle";
export const categoryIsSoldOut = "categoryIsSoldOut";
export const categoryIsSoldOutDescription = "categoryIsSoldOutDescription";
export const categoryIsRunOutIndefinitely = "categoryIsRunOutIndefinitely";
export const categoryIsRunOutIndefinitelyDescription =
  "categoryIsRunOutIndefinitelyDescription";
export const modifierGroupIsSoldOut = "modifierGroupIsSoldOut";
export const modifierGroupIsSoldOutDescription =
  "modifierGroupIsSoldOutDescription";
export const modifierGroupIsRunOutIndefinitely =
  "modifierGroupIsRunOutIndefinitely";
export const modifierGroupIsRunOutIndefinitelyDescription =
  "modifierGroupIsRunOutIndefinitelyDescription";
export const modifierItemIsSoldOut = "modifierItemIsSoldOut";
export const modifierItemIsSoldOutDescription =
  "modifierItemIsSoldOutDescription";
export const modifierItemIsRunOutIndefinitely =
  "modifierItemIsRunOutIndefinitely";
export const modifierItemIsRunOutIndefinitelyDescription =
  "modifierItemIsRunOutIndefinitelyDescription";
