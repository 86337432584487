export const noResultsFound = "لا يوجد أي نتائج";
export const itemAvailable = "الصنف متاح";
export const itemIsSoldOut = "نفذ الصنف اليوم";
export const itemIsSoldOutDescription =
  "سيكون هذا الصنف متاحًا في اليوم التالي تلقائيًا";
export const itemIsRunOutIndefinitely = "نفذ الصنف الي اجل غير مسمي";
export const itemIsRunOutIndefinitelyDescription =
  "سيكون هذا الصنف متاحًا عند تغيير حالته يدويًا";
export const hideItemFromTheBranch = "إخفاء الصنف من الفرع";
export const hideItemFromTheBranchDescription =
  "لا يمكن للعملاء مشاهدة الصنف وطلبه من هذا الفرع";
export const itemSettings = "إعدادات الصنف";
export const saveChanges = "حفظ التغييرات";
export const menu = "قائمة الطعام";
export const confirmSimilarChanges = "تغيير الاصناف المماثلة";
export const selectTheItemState = "اختر حالة الصنف";
export const changeSimilarTitle = "تغيير عناصر الاصناف المتشابهة";
export const changeSimilarSubTitle =
  '{{similarCount}} من العناصر المعدلة مشابهة لـهذا التعديل ، انقر فوق "تغيير الاصناف المماثلة" لتطبيق التغيير عليها جميعًا أو "إلغاء" لحفظ عنصر واحد فقط';
export const cancelSimilarChanges = "الغاء";
export const selectTheCategoryState = "اختر حالة الفئة";
export const selectTheModifierGroupState = "اختر حالة مجموعة الاصناف";
export const selectTheModifierItemState = "اختر حالة الصنف";
export const categoryIsSoldOut = "لقد نفدت هذه الفئة لليوم";
export const categoryIsSoldOutDescription =
  "هذه الفئة ستكون متاحة في اليوم التالي تلقائًا";
export const categoryIsRunOutIndefinitely = "لقد نفدت هذه الفئة لوقت غير محدد";
export const categoryIsRunOutIndefinitelyDescription =
  "ستتاح هذه الفئة حين تغير حالتها يدويًا ";
export const modifierGroupIsSoldOut = "لقد نفدت مجموعة الاصناف هذه لليوم";
export const modifierGroupIsSoldOutDescription =
  "ستتاح مجموعة الاصناف هذه تلقائيًا في اليوم التالي";
export const modifierGroupIsRunOutIndefinitely =
  "لقد نفدت مجموعة الاصناف هذه لوقت غير محدد";
export const modifierGroupIsRunOutIndefinitelyDescription =
  "ستتاح مجموعة الاصناف هذه حين تغير حالتها يدويًا";
export const modifierItemIsSoldOut = "لقد نفد الصنف هذا لليوم";
export const modifierItemIsSoldOutDescription =
  "سيتاح الصنف هذا تلقائيًا في اليوم التالي";
export const modifierItemIsRunOutIndefinitely =
  "لقد نفد الصنف هذا لوقت غير محدد";
export const modifierItemIsRunOutIndefinitelyDescription =
  "سيتاح الصنف هذا حين تغير حالته يدويًا";
