import React, { useMemo } from "react";
import * as styles from "./styles.module.sass";
import * as Strings from "../../../i18n/strings/newOrder";
import { useTranslation } from "../../../hooks/useTranslation";
import { getRtlClass, useRtlClass } from "../../../../lib";
import { storedOrderType } from "../../../redux/reducers/getOrdersReducer";

type switchLangFn = <T extends {}>(
  arabicString: any,
  englishString: any
) => string;

export const useSwitchStringLang: switchLangFn = (
  arabicString,
  englishString
) => {
  const isArabic = useRtlClass({ rtl: "true" });
  return useMemo(() => (isArabic ? arabicString : englishString), [
    isArabic,
    englishString,
    arabicString
  ]);
};

export const switchStringLang: switchLangFn = (arabicString, englishString) => {
  const isArabic = getRtlClass({ rtl: true });
  return isArabic ? arabicString : englishString;
};

interface IDeliveryLocationProps {
  order: storedOrderType;
}

const DeliveryLocation: React.FC<IDeliveryLocationProps> = props => {
  const {
    district_ar_name,
    district_en_name,
    sub_district_ar_name,
    sub_district_en_name
  } = props.order;
  const { t } = useTranslation("newOrder");

  return (
    <div
      className={styles.pickUpTimeWrapper}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <span className={styles.userName} style={{ fontSize: "14px" }}>
        {t(Strings.arrive)}{" "}
      </span>
      <span
        className={styles.info}
        style={{ fontSize: "18px", fontWeight: "bold" }}
      >
        {useSwitchStringLang(district_ar_name, district_en_name)} -{" "}
        {useSwitchStringLang(sub_district_ar_name, sub_district_en_name)}
      </span>
    </div>
  );
};

export default DeliveryLocation;
