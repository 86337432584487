import axios from "./axios";
import * as requests from "./requests";
import { ChangeableStatus, OrderStatusChange } from "../constants/types";

export const changeOrderStatus = (
  token: string,
  order_id: string,
  status: ChangeableStatus,
  reason_id?: number
) => {
  return axios.put(
    requests.changeOrderStatus(OrderStatusChange[status]),
    {
      order_id,
      reason_id
    },
    {
      headers: {
        token
      }
    }
  );
};
