import * as constants from "../../constants";
import { IAuthAction, ILoginSuccessAction } from "../../types";
import { mutateState } from "../../helpers/mutate-state";
import { withLoadingReducer } from "../../Pickup-App/redux/reducers/withLoadingState";
import { CashierApps } from "../../components/AppSelector";
import { union } from "ts-action";
import { remoteLoginSuccess } from "../actions/auth-actions";

const TOKEN = "token";
const ID = "id";
const IS_LOGGED_IN = "isLoggedIn";
const CALL_CENTER = "call_center";
const HAS_POS = "hasPOS";
const SELECTED_APP = "selected_app";
const CASHIER_STORE_ID = "cashier_store_id";
const CASHIER_CODE = "cashier_code";
export interface IAuthReducerState {
  [IS_LOGGED_IN]: boolean;
  isLoggedInRemotely: boolean;
  [HAS_POS]: boolean;
  [TOKEN]: string;
  [CASHIER_STORE_ID]: string;
  [ID]: string;
  [CALL_CENTER]: undefined | 1;
  [CASHIER_CODE]: number;
  [SELECTED_APP]: CashierApps;
}

const initialState: IAuthReducerState = {
  [TOKEN]: "",
  [IS_LOGGED_IN]: false,
  [ID]: "",
  [CALL_CENTER]: undefined,
  [HAS_POS]: false,
  [SELECTED_APP]: CashierApps.pickup,
  [CASHIER_STORE_ID]: "",
  isLoggedInRemotely: false,
  [CASHIER_CODE]: 0
};

const remoteLoginActionType = union({ remoteLoginSuccess });
const authReducer = withLoadingReducer(
  (
    state: IAuthReducerState = initialState,
    action: IAuthAction | typeof remoteLoginActionType
  ): IAuthReducerState => {
    switch (action.type) {
      case constants.loginAction.fulfilled:
      case constants.switchAccountAction.fulfilled: {
        return mutateState(state, map => {
          const { payload } = action as ILoginSuccessAction;
          const callCenter = payload.call_center || 0;
          map.set(TOKEN, payload.token);
          map.set(ID, payload.id);
          map.set(IS_LOGGED_IN, true);
          map.set(CALL_CENTER, callCenter);
          map.set(HAS_POS, Boolean(payload.store_pos));
          map.set(CASHIER_STORE_ID, payload.store_id);
          map.set(CASHIER_CODE, payload.cashier_code);
        });
      }
      case constants.logoutAction.fulfilled: {
        return initialState;
      }
      case constants.ACTION_SWITCH_APPS: {
        return mutateState(state, map => {
          map.set(SELECTED_APP, (action as any).payload);
        });
      }
      case remoteLoginSuccess.type: {
        const { payload } = action as typeof remoteLoginActionType;
        return mutateState(state, map => {
          map.set(TOKEN, payload.token);
          map.set(ID, payload.cashier_id);
          map.set(IS_LOGGED_IN, true);
          map.set(HAS_POS, Boolean(payload.store_pos));
          map.set(CASHIER_STORE_ID, payload.store_id);
          map.set("isLoggedInRemotely", true);
        });
      }
      default:
        return state;
    }
  },
  constants.loginAction
);

export default authReducer;
