import axios from "./axios";
import { modifierGroupAvailability } from "./requests";
import { MenuEntityAvailability } from "../redux/actions/menuActions";
import { ChangeableAvailability } from "./changeItemAvailability";

export const changeModifierGroupAvailability = (
  token: string,
  branch_id: string,
  id: string,
  availability: MenuEntityAvailability
) => {
  return axios.put(
    modifierGroupAvailability + "/" + id,
    { branch_id, availability: ChangeableAvailability[availability] },
    {
      headers: {
        token
      }
    }
  );
};
