import { select, takeLatest, put, call } from "redux-saga/effects";
import { selectToken } from "../../../redux-store/selectors";
import { getBranchesFailure, getBranchesSuccess } from "../actions/branches";
import { getBranchesAction } from "../../../constants";
import { getCalCenterAgentBranchesApi } from "../../../Pickup-App/axios/getCallCenterAgentBranchesApi";

function* getBranchesSaga() {
  try {
    const token = yield select(selectToken);
    const res = yield call(getCalCenterAgentBranchesApi, token);
    yield put(getBranchesSuccess(res.data?.branches || []));
  } catch (e) {
    yield put(getBranchesFailure(e));
  }
}

export function* watchGetBranchesSaga() {
  yield takeLatest(getBranchesAction.requested, getBranchesSaga);
}
