import axios from "./axios";
import { OrderStatus } from "../constants/types";

export const markOrdersReceivedSeenAPI = (
  token: string,
  orderIds: string[],
  status: OrderStatus.received | OrderStatus.seen
) => {
  return axios.put(
    `api/cashier/call-center/orders/status/${status}`,
    {
      orders_ids: orderIds
    },
    {
      headers: {
        token
      }
    }
  );
};
