import { logCurrentAppVersion } from "../actions";
import { takeLatest, retry, select } from "redux-saga/effects";
import { logCurrentAppVersionApi } from "../../axios/logCurrentAppVersion";
import { selectToken } from "../selectors";
import { union } from "ts-action";

const actionType = union({ logCurrentAppVersion });
function* logCurrentAppVersionSaga({
  payload: { app_version }
}: typeof actionType) {
  const token = yield select(selectToken);
  yield retry(5, 10000, logCurrentAppVersionApi, {
    branch_id: "branch_id",
    token,
    app_version
  });
}

export function* watchLogCurrentAppVersionSaga() {
  yield takeLatest(logCurrentAppVersion.type, logCurrentAppVersionSaga);
}
