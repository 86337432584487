import instance from "./axios";
import * as requests from "./requests";
import { IBranchAvgPrepTime } from "./branchAveragePrepTime";

export const orderAveragePrepTimeApi = {
  get: (token: string, order_id: string) =>
    instance.get<IBranchAvgPrepTime>(requests.orderAvgPrepTime(order_id), {
      headers: {
        token
      }
    }),

  put: (token: string, order_id: string, data: IBranchAvgPrepTime) =>
    instance.put(requests.orderAvgPrepTime(order_id), data, {
      headers: {
        token
      }
    })
};
