import { IRootReducerState } from "../../redux-store/rootReducer";
import { useDispatch } from "react-redux";
// import { getOS } from "../../../Utils/getOS";
import * as React from "react";
import loadable from "@loadable/component";
import { useInactive } from "../../../Utils/custom-hooks/use-inactive";
// import { ENABLE_MOBILE } from "../../../env/environment";
import {
  setAppActiveState,
  listenToNetworkChange
} from "../../redux-store/actions";
import { authenticateSocket } from "../../Pickup-App/redux/actions/socket-actions";
import { useSelect } from "../../helpers/use-select";
import LockScreen from "../../Pickup-App/components/LockScreen";

// const PickupApp = loadable(() => import("../../Pickup-App/pickupApp"));
const LoginPortal = loadable(() => import("../../web-components/Login"));
const CallCenterApp = loadable(() =>
  import("../../CallCenter-App/CallCenterApp")
);

export enum CashierApps {
  PCLoyalty = "PCLoyalty",
  mobileLoyalty = "mobileLoyalty",
  pickup = "pickup",
  login = "login"
}

const AppsMap: { [x: string]: React.ComponentType } = {
  [CashierApps.pickup]: CallCenterApp,
  [CashierApps.login]: LoginPortal
};

const AppSelector: React.FC = () => {
  const { appToShow, authenticated } = useSelect(getAppToShowFromState);
  const AppropriatedApp = React.useMemo(() => AppsMap[appToShow], [appToShow]);
  const { inactive } = useInactive();
  const dispatch = useDispatch();
  React.useEffect(
    () => {
      dispatch(setAppActiveState(!inactive));
    },
    [inactive]
  );
  React.useEffect(() => {
    dispatch(listenToNetworkChange());
  }, []);

  React.useEffect(
    () => {
      if (authenticated) {
        dispatch(authenticateSocket());
        // dispatch(
        //   logCurrentAppVersion({
        //     app_version: localStorage.getItem("appVersion") || "Unknown"
        //   })
        // );
      }
    },
    [authenticated]
  );
  return (
    <>
      <LockScreen />
      <AppropriatedApp />
    </>
  );
};

export const getLoyaltyAppToShow = () => {
  return "";
};

export const getAppToShowFromState = (state: IRootReducerState) => {
  const {
    authReducer: {
      isLoggedIn
      // hasSelectedBranch
      // selected_app
    }
  } = state;
  const selectedBranchHasOrdering = true;

  const authenticated = isLoggedIn;

  // const appToShow = selected_app;
  // Mobile interface had its own login

  // const loginToShow = shouldShowMobile ? CashierApps.login : CashierApps.login;
  return {
    appToShow: authenticated ? CashierApps.pickup : CashierApps.login,
    authenticated,
    selectedBranchHasPickup: selectedBranchHasOrdering
  };
};

export default AppSelector;
