import { takeLatest, select, put, call } from "redux-saga/effects";
import * as actions from "../actions/menuActions";
import { toggleModifierGroupAction } from "../../constants/actions";
import { selectOrderingMenuDB, selectToken } from "../../../redux-store/selectors";
import { union } from "ts-action";
import { changeModifierGroupAvailability } from "../../axios/changeModifierGroupAvailability";

const actionType = union({ toggleCategory: actions.toggleCategory });
function* toggleModifierGroupSaga(action: typeof actionType) {
  try {
    const token = yield select(selectToken);
    const orderingMenuDBInstance = yield select(selectOrderingMenuDB);

    yield call(
      changeModifierGroupAvailability,
      token,
      action.payload.branch_id,
      action.payload.id,
      action.payload.status
    );
    yield put(actions.toggleModifierGroupSuccess(action.payload));
    yield call(async () => {
      const value = await orderingMenuDBInstance?.modifiers_groups.get(action.payload.id);
      await orderingMenuDBInstance?.modifiers_groups.update(action.payload.id, { enabled: Number(!value.enabled) })
      await orderingMenuDBInstance?.modifiers.where({ modifier_group_id: action.payload.id }).modify({ enabled: Number(!value.enabled) })


    })
  } catch (e) {
    yield put(actions.toggleModifierGroupFailure(action.payload));
  }
}

export function* watchToggleModifierGroupSaga() {
  yield takeLatest(
    toggleModifierGroupAction.requested,
    toggleModifierGroupSaga
  );
}
