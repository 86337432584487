import React, { useMemo } from "react";
import * as styles from "./styles.module.sass";
import { SettingsSwitch } from "../SittingSwitch";
import { SingleMenuItem } from "./SingleMenuItem";
import { IItem, IdentifiedModifierGroup } from "../../../redux/reducers/lib";
import { toggleCategory } from "../../../redux/actions/menuActions";
import { LoadingStatus } from "../../../redux/reducers/withLoadingState";
import { useSelect } from "../../../../helpers/use-select";
import { DirectedText } from "../../../../components/DirectedText";
import { useRtlClass } from "../../../../lib";
import { useTranslatedName, useHandleMenuEnabled } from "../util";

interface ISingleCategoryItemProps {
  active: boolean;
  itemNumber: number;
  id: string;
  menuItems: Array<{ item: IItem; modifierGroups: IdentifiedModifierGroup[] }>;
  whiteMode?: boolean;
  handleModifierItemChange: Function;
}

export const SingleCategoryItem: React.FC<ISingleCategoryItemProps> = props => {
  const {
    active,
    itemNumber,
    menuItems: MenuItems,
    id,
    handleModifierItemChange
  } = props;
  const { modifierGroupsById, categoriesById } = useSelect(
    state => state.menuReducer
  );

  const MenuItemsArray = useMemo(
    () =>
      MenuItems.map(({ item, modifierGroups }, index) => (
        <SingleMenuItem
          handleModifierItemChange={handleModifierItemChange}
          whiteMode={props.whiteMode}
          key={index}
          id={item.id}
          imgPath={item.image}
          desc={item.description}
          price={item.price}
          parentEnabled={active}
          status={item.status}
          modifierGroups={modifierGroups.map(
            ({ id: modifierGroupId, modifierItems }) => ({
              group: modifierGroupsById[modifierGroupId],
              modifiers: modifierItems
            })
          )}
        />
      )),
    [MenuItems, active]
  );

  const selectedCategory = categoriesById[id];

  const handleChange = useHandleMenuEnabled(
    true,
    Boolean(selectedCategory.enabled),
    id,
    toggleCategory
  );

  return (
    <div
      className={`${
        props.whiteMode ? styles.whiteModeMain : styles.main
      } ${useRtlClass(styles)}`}
    >
      <div className={styles.header} id={props.id}>
        <span>
          <DirectedText>{`${useTranslatedName(
            selectedCategory
          )}`}</DirectedText>
          ({itemNumber})
        </span>
        <div className={styles.toggle}>
          <SettingsSwitch
            disabled={selectedCategory.loadingStatus === LoadingStatus.loading}
            className={styles.switch}
            onChange={handleChange}
            checked={active}
          />
        </div>
      </div>
      <div className={styles.content}>{MenuItemsArray}</div>
    </div>
  );
};
